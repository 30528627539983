import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const PauseButton = ({
  testID="pause-button",
  width = 32,
  height = 24,
  color = '#616569',
}: SvgProps) => (
  <Svg testID={testID} width={width} height={height} fill="none" viewBox="0 0 32 24">
    <Path fill={color} d="M4 0h24s4 0 4 12-4 12-4 12H4s-4 0-4-12S4 0 4 0Z" />
    <Path fill="#fff" d="M12 6v12h2V6h-2ZM18 6v12h2V6h-2Z" />
  </Svg>
);
