import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const DrawerIcon = ({
  width = 24,
  height = 24,
  color = '#636567',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7h20V5H2v2Zm0 6h20v-2H2v2Zm16 6H2v-2h16v2Z"
      fill={color}
    />
  </Svg>
);
