import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LivestreamCameraIcon = ({
  width = 20,
  height = 14,
  fill = '#838587',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      d="M15 5.5V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h13c.55 0 1-.45 1-1V8.5l5 5V.5l-5 5Z"
      fill={fill}
    />
  </Svg>
);

export default LivestreamCameraIcon;
