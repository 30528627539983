import * as React from 'react';
import Svg, { SvgProps, Rect } from 'react-native-svg';

export function Connection({
  width = 24,
  height = 24,
  testID = 'connectionIcon',
  color = '#221F20',
  ...props
}: SvgProps) {
  return (
    <Svg width={width} height={height} testID={testID} fill="none" {...props}>
      <Rect width={3} height={14} x={18} y={5} fill={color} rx={1} />
      <Rect width={3} height={11} x={13} y={8} fill={color} rx={1} />
      <Rect width={3} height={8} x={8} y={11} fill={color} rx={1} />
      <Rect width={3} height={5} x={3} y={14} fill={color} rx={1} />
    </Svg>
  );
}
