import React from 'react';
import {
  ScrollView,
  ScrollViewProps,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { useTheme } from '../../themes';
import { StyledText } from '../Typography/StyledText';
export interface ScreenContainerProps extends ScrollViewProps {
  headerText?: string;
  scrollViewContentContainerStyle?: StyleProp<ViewStyle>;
  customContainerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

const createStyles = () =>
  StyleSheet.create({
    container: {
      flex: 0.8,
      marginHorizontal: 20,
      marginVertical: 24,
    },
    headerText: {
      marginLeft: 10,
      marginBottom: 24,
    },
    scrollViewContent: {
      paddingBottom: 100,
    },
  });

export function ScreenContainer({
  children,
  headerText,
  scrollViewContentContainerStyle,
  customContainerStyle,
  ...rest
}: ScreenContainerProps) {
  const theme = useTheme();
  const styles = createStyles();

  return (
    <ScrollView
      style={[styles.container, customContainerStyle]}
      contentContainerStyle={[
        styles.scrollViewContent,
        scrollViewContentContainerStyle,
      ]}
      {...rest}
    >
      {headerText ? (
        <StyledText
          textStyle={theme.textStyles.header2Bold}
          style={styles.headerText}
        >
          {headerText}
        </StyledText>
      ) : null}

      {children ? children : null}
    </ScrollView>
  );
}
