import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const LeftArrow = ({ width = 24, height = 24, ...rest }: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 10H7.5L12 5.5l-2-2-8 8 8 8 2-2L7.5 13H22v-3Z"
      fill="#F50"
    />
  </Svg>
);
