import React, { useCallback, useMemo, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { useTheme } from '../../themes';
import { StyledText } from '../Typography/StyledText';
import { ComponentTheme } from '../../themes/defaultTheme';
import { Markdown } from '..';

export interface AccordionProps {
  /**
   * Optional prop to define if the accordion is open when first rendering it.
   */
  isOpenByDefault?: boolean;
  /**
   * Header text that is always shown.
   */
  header: string;
  /**
   * Body text that may be hidden until a user clicks "Read more"
   */
  body: string[];
  /**
   * The text for the pressable that indicates to the user that the accordion can be expanded (Ex: "Read more")
   */
  indicateToOpenText?: string;
  /**
   * The text for the pressable that indicates to the user to close the body of the accordion (Ex: "Read less")
   */
  indicateToCloseText?: string;
  /**
   * An additional function to be called whenever a user opens or closes the accordion.
   */
  onExpandPress?: () => void;
}

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    bodyText: { paddingTop: 8 },
    readMorePressable: {
      paddingTop: 16,
    },
    readMoreText: {
      color: theme.text.accent,
    },
  });

export function Accordion({
  isOpenByDefault = false,
  header,
  body,
  indicateToCloseText = 'Read less',
  indicateToOpenText = 'Read more',
  onExpandPress,
}: AccordionProps) {
  const theme = useTheme();
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpenByDefault);
  const styles = createStyles(theme);

  const openOrCloseAccordion = useCallback(() => {
    if (typeof onExpandPress === 'function') {
      onExpandPress();
    }
    setIsAccordionOpen(!isAccordionOpen);
  }, [isAccordionOpen]);

  const bodyTextMapped = useMemo(
    () =>
      body.map((bodyText, index) => (
        <Markdown key={index}>{bodyText}</Markdown>
      )),
    [body],
  );

  return (
    <>
      <StyledText textStyle={theme.textStyles.header2Bold}>{header}</StyledText>
      {isAccordionOpen && bodyTextMapped}
      <Pressable
        accessibilityRole="button"
        style={styles.readMorePressable}
        onPress={openOrCloseAccordion}
      >
        <StyledText
          textStyle={theme.textStyles.header1Bold}
          style={styles.readMoreText}
        >
          {isAccordionOpen ? indicateToCloseText : indicateToOpenText}
        </StyledText>
      </Pressable>
    </>
  );
}
