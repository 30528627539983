import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ArrowIcon = ({
  height = 8,
  width = 29,
  color = '#000',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      d="M28.354 4.354a.5.5 0 0 0 0-.708L25.172.464a.5.5 0 1 0-.707.708L27.293 4l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182ZM0 4.5h28v-1H0v1Z"
      fill={color}
    />
  </Svg>
);
