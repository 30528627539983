import React from 'react';
import { useTheme } from '../../themes';
import { getLoaderStyles } from './styles';
import { ActivityIndicator, View } from 'react-native';
import { LoaderProps } from './types';

export const Loader: React.FC<LoaderProps> = ({
  loaderId = 'generic-loader',
  size = 'large',
  ...rest
}) => {
  const theme = useTheme();
  const loaderStyle = getLoaderStyles(theme);
  return (
    <View style={loaderStyle.loadingContainer}>
      <ActivityIndicator
        size={size}
        color={theme.colors.primaryOrange}
        testID={loaderId}
        {...rest}
      />
    </View>
  );
};
