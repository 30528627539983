type FontFamiliesKeys = keyof typeof FontFamilies;
export type FontFamiliesType = {
  [key in FontFamiliesKeys]: string;
};

export enum FontFamilies {
  header = 'Px-Grotesk',
  headerBold = 'Px-Grotesk-Bold',
  headerItalic = 'Px-Grotesk-Italic',
  headerBoldItalic = 'Px-Grotesk-Bold-Italic',
  body = 'Inter',
  bodySemiBold = 'Inter-SemiBold',
}
