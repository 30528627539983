import React, { useEffect, useReducer } from 'react';
import {
  EmailIcon,
  FormTextInput,
  PasswordIcon,
  StyledButton,
  StyledText,
  useTheme,
} from '../..';
import { View, StyleSheet, ScrollView } from 'react-native';
import { ComponentTheme } from '../../themes/defaultTheme';
import { createAccountReducer } from './createAccountReducer';
import Validators from '../../utils/Validators';

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    screenContainer: {
      backgroundColor: theme.background.main,
      flexGrow: 1,
      alignItems: 'center',
      paddingBottom: '5%',
    },
    buttonHeight: {
      minHeight: 54,
    },
    cardContainer: {
      width: '90%',
      marginBottom: '4%',
      padding: 20,
      backgroundColor: theme.background.main,
      borderRadius: theme.radii.radiusLarge,
    },
    submitButtonContainer: {
      width: '90%',
      paddingHorizontal: 20,
    },
    signInLinkContainer: {
      width: '70%',
      flexDirection: 'row',
      marginBottom: '3%',
      justifyContent: 'space-between',
    },
    phoneCountryCode: {
      width: 60,
      marginRight: 10,
      flexGrow: 0,
    },
    titleContainer: {
      margin: 10,
    },
    phoneRow: {
      flexDirection: 'row',
      alignContent: 'center',
    },
    phoneAlignment: {
      marginTop: 'auto',
    },
    phoneInput: {
      flex: 1,
    },
    privacyPolicyText: {
      marginBottom: '4%',
    },
    checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginBottom: '2%',
    },
    signInText: {
      textDecorationLine: 'underline',
    },
  });

const initialFormState = {
  firstName: '',
  lastName: '',
  phone: '',
  countryCode: '',
  email: '',
  password: '',
  confirmPassword: '',
  isPrivacyPolicyChecked: true,
  submitForm: false,
  validators: {
    firstName: '',
    lastName: '',
    phone: '',
    countryCode: '',
    email: '',
    password: '',
    confirmPassword: '',
    isPrivacyPolicyChecked: '',
  },
  createError: '',
  createSuccess: '',
};

export function CustomForm() {
  const [formState, setFormState] = useReducer(
    createAccountReducer,
    initialFormState,
  );

  useEffect(() => {
    (async () => {
      if (formState.submitForm) {
        console.log('Submitted ', formState);
      }
      setFormState({
        type: 'change',
        payload: {
          submitForm: false,
        },
      });
    })();
  }, [formState.submitForm]);

  const theme = useTheme();

  const styles = createStyles(theme);
  return (
    <>
      <ScrollView contentContainerStyle={styles.screenContainer}>
        <View style={styles.titleContainer}>
          <StyledText>Create Account</StyledText>
        </View>
        <View style={styles.cardContainer}>
          <FormTextInput
            textInputProps={{
              onChangeText: (inputFirstName) =>
                setFormState({
                  type: 'change',
                  payload: {
                    firstName: inputFirstName,
                    validators: {
                      firstName: Validators.mandatory(inputFirstName),
                    },
                  },
                }),
              placeholder: 'First Name',
              placeholderTextColor: theme.text.placeholder,
              returnKeyType: 'next',
            }}
            errorMessage={formState.validators.firstName}
            isInErrorState={formState.validators.firstName !== ''}
          />

          <FormTextInput
            textInputProps={{
              onChangeText: (inputLastName) =>
                setFormState({
                  type: 'change',
                  payload: {
                    lastName: inputLastName,
                    validators: {
                      lastName: Validators.mandatory(inputLastName),
                    },
                  },
                }),
              placeholder: 'Last Name',
              placeholderTextColor: theme.text.placeholder,
              returnKeyType: 'next',
            }}
            errorMessage={formState.validators.lastName}
            isInErrorState={formState.validators.lastName !== ''}
          />

          <View style={styles.phoneRow}>
            <FormTextInput
              textInputProps={{
                onChangeText: (inputCountryCode) =>
                  setFormState({
                    type: 'change',
                    payload: {
                      countryCode: inputCountryCode,
                      validators: {
                        countryCode: Validators.mandatory(inputCountryCode),
                      },
                    },
                  }),
                placeholder: 'Code',
                placeholderTextColor: theme.text.placeholder,
                keyboardType: 'phone-pad',
              }}
              viewContainerStyles={styles.phoneCountryCode}
              isInErrorState={formState.validators.countryCode}
            />

            <FormTextInput
              textInputProps={{
                onChangeText: (inputPhone) =>
                  setFormState({
                    type: 'change',
                    payload: {
                      phone: inputPhone,
                      validators: {
                        phone: Validators.mandatory(inputPhone),
                      },
                    },
                  }),
                placeholder: 'Phone',
                placeholderTextColor: theme.text.placeholder,
                maxLength: 12,
                keyboardType: 'phone-pad',
              }}
              viewContainerStyles={styles.phoneInput}
              errorMessage={formState.validators.phone}
              isInErrorState={formState.validators.phone}
              style={[styles.phoneAlignment, styles.phoneInput]}
            />
          </View>

          <FormTextInput
            textInputProps={{
              onChangeText: (inputEmail) => {
                setFormState({
                  type: 'change',
                  payload: {
                    email: inputEmail,
                    validators: { email: Validators.email(inputEmail) },
                  },
                });
              },
              placeholder: 'Enter email',
              placeholderTextColor: theme.text.placeholder,
              keyboardType: 'email-address',
              returnKeyType: 'next',
              leftIcon: () => <EmailIcon />,
            }}
            errorMessage={formState.validators.email}
            isInErrorState={formState.validators.email !== ''}
          />
          <FormTextInput
            textInputProps={{
              onChangeText: (inputPassword) =>
                setFormState({
                  type: 'change',
                  payload: { password: inputPassword },
                }),
              placeholder: 'Create a password',
              placeholderTextColor: theme.text.placeholder,
              secureTextEntry: true,
              returnKeyType: 'next',
              leftIcon: () => <PasswordIcon />,
            }}
            errorMessage={formState.validators.password}
          />

          <FormTextInput
            textInputProps={{
              onChangeText: (inputConfirmPassword) =>
                setFormState({
                  type: 'change',
                  payload: { confirmPassword: inputConfirmPassword },
                }),
              placeholder: 'Confirm password',
              placeholderTextColor: theme.text.placeholder,
              secureTextEntry: true,
              returnKeyType: 'next',
              leftIcon: () => <PasswordIcon />,
            }}
            errorMessage={formState.validators.confirmPassword}
          />
        </View>
        <View style={styles.submitButtonContainer}>
          <StyledButton
            style={styles.buttonHeight}
            onPress={() => {
              setFormState({
                type: 'change',
                payload: {
                  validators: {
                    firstName: Validators.mandatory(formState.firstName),
                    lastName: Validators.mandatory(formState.lastName),
                    phone: Validators.mandatory(formState.phone),
                    countryCode: Validators.mandatory(formState.countryCode),
                    email: Validators.email(formState.email),
                    password: '',
                    confirmPassword: '',
                  },
                },
              });

              setFormState({
                type: 'submit',
                payload: {},
              });
            }}
          >
            Create Account
          </StyledButton>
        </View>
      </ScrollView>
    </>
  );
}
