import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export function ChecklistIcon({
  width = 24,
  height = 24,
  color = '#636567',
  testID = 'checklistIcon',
  ...props
}: SvgProps) {
  return (
    <Svg width={width} height={height} fill="none" testID={testID} {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M3.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2Zm5 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-12Zm0 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-12ZM8 17.5a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-12a.5.5 0 0 1-.5-.5v-1Zm-5-6a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1Zm.5 5.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
