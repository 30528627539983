import React, {
  useCallback,
  useRef,
  forwardRef,
  ForwardedRef,
  useEffect,
} from 'react';
import {
  View,
  TextInput,
  TextInputProps,
  StyleSheet,
  StyleProp,
  ViewStyle,
  Animated,
  ColorValue,
  NativeSyntheticEvent,
  TextInputEndEditingEventData,
  TextInputFocusEventData,
  Platform,
} from 'react-native';
import { Field } from '../Form';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';
import { StyledText } from '../Typography/StyledText';

const createStyles = (
  theme: ComponentTheme,
  yVal: any,
  leftIcon: (() => JSX.Element | undefined) | undefined,
  placeholderTextColor: ColorValue | undefined,
) =>
  StyleSheet.create({
    inputContainer: {
      backgroundColor: theme.background.surface,
      borderRadius: theme.radii.radiusMedium,
      borderWidth: 1,
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      paddingVertical: 5,
    },
    defaultInput: {
      flexGrow: 1,
      flex: 1,
      borderColor: 'transparent',
      overflow: 'hidden',
      marginTop: 20,
      minHeight: 28,
    },
    error: {
      borderColor: theme.alerts.error,
    },
    iconContainer: {
      zIndex: -10,
      width: 68,
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 26,
    },

    floatingLabelContainer: {
      left: leftIcon ? 68 : 0,
      position: 'absolute',
      zIndex: -10,
      transform: [{ translateY: yVal }],
    },

    placeholderText: {
      color: placeholderTextColor
        ? placeholderTextColor
        : theme.text.placeholder,
    },
  });
export interface InputTextProps extends TextInputProps {
  field?: Field;
  testID?: string;
  items?: string[];
  placeholder?: string;
  /**
   * The current value input by the user. This is exposed so that you may keep track of
   * changes to the value.
   */
  fieldStateValue?: string;
  /**
   * Allows you to pass text input props. (https://reactnative.dev/docs/textinput#props)
   */
  textInputProps?: TextInputProps;
  /**
   * Used to display a red border around the input. Indicates to the user that the input
   * requires attention.
   */
  isInErrorState?: boolean;
  /**
   * Allows you to pass in an icon which is displayed to the left of the input text
   * (usually to indicate the type of text the user should input).
   */
  leftIcon?: () => JSX.Element | undefined;
  /**
   * Allows you to pass in an icon which is displayed to the right of the input text
   * (usually to indicate additional functionality, such as clicking on it
   * to hide a password).
   */
  rightIcon?: () => JSX.Element | undefined;
  /**
   * Allows you to overwrite the style of the container surrounding the text input.
   */
  viewContainerStyles?: StyleProp<ViewStyle>;
}

export const defaultHitSlop = { top: 30, left: 20, right: 20, bottom: 30 };
export const InputText = forwardRef(function InputText(
  {
    field,
    fieldStateValue,
    value,
    textInputProps,
    isInErrorState = false,
    rightIcon,
    placeholder,
    leftIcon,
    accessibilityHint = `Text Input for ${placeholder}`,
    style,
    viewContainerStyles,
    ...rest
  }: InputTextProps,
  ref: ForwardedRef<TextInput>,
) {
  const moveText = useRef(new Animated.Value(0)).current;
  const moveTextTop = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (rest.onFocus) {
        rest.onFocus(e);
      }
      Animated.timing(moveText, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    },
    [],
  );

  const moveTextBottom = useCallback(
    (
      e: NativeSyntheticEvent<
        TextInputEndEditingEventData | TextInputFocusEventData
      >,
    ) => {
      if (rest.onBlur) {
        rest.onBlur(e as NativeSyntheticEvent<TextInputFocusEventData>);
      }
      if (rest.onEndEditing) {
        rest.onEndEditing(
          e as NativeSyntheticEvent<TextInputEndEditingEventData>,
        );
      }
      if (
        fieldStateValue?.length === 0 ||
        value?.length === 0 ||
        e?.nativeEvent?.text === ''
      ) {
        Animated.timing(moveText, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }).start();
      }
    },
    [fieldStateValue],
  );

  const floatingLabelYVal = moveText.interpolate({
    inputRange: [0, 1],
    outputRange: [0, Platform.OS === 'web' ? -20 : -10],
  });

  const theme = useTheme();
  const styles = createStyles(
    theme,
    floatingLabelYVal,
    leftIcon,
    rest.placeholderTextColor,
  );

  useEffect(() => {
    if (fieldStateValue) {
      Animated.timing(moveText, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
    return () => {};
  }, []);

  return (
    <View
      style={[
        styles.inputContainer,
        {
          borderColor: isInErrorState
            ? theme.alerts.error
            : theme.background.surface,
        },
        viewContainerStyles,
      ]}
    >
      {leftIcon && <View style={styles.iconContainer}>{leftIcon()}</View>}

      <Animated.View style={[styles.floatingLabelContainer]}>
        <StyledText
          style={styles.placeholderText}
          textStyle={theme.textStyles.bodyMedium}
        >
          {placeholder}
        </StyledText>
      </Animated.View>

      <TextInput
        {...rest}
        {...(field?.inputProps as Record<string, string>)}
        style={[styles.defaultInput, theme.textStyles.bodySemiBold, style]}
        value={fieldStateValue}
        accessibilityLabel={placeholder}
        accessibilityHint={accessibilityHint}
        onFocus={moveTextTop}
        onBlur={moveTextBottom}
        onEndEditing={moveTextBottom}
        hitSlop={defaultHitSlop}
        ref={ref}
      />

      {rightIcon && <View style={styles.iconContainer}>{rightIcon()}</View>}
    </View>
  );
});
