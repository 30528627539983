import Svg, { Path, SvgProps } from 'react-native-svg';
import React from 'react';

export function SelectedCheckBox({
  testID,
  color = '#2B2220',
  ...props
}: SvgProps) {
  return (
    <Svg width={16} height={16} fill="none" {...props} testID={testID}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.51 2.63c.072-.07.168-.11.268-.11h7.8a1.04 1.04 0 1 0 0-2.08h-7.8A2.458 2.458 0 0 0 .32 2.897v9.927a2.458 2.458 0 0 0 2.458 2.458h9.928a2.458 2.458 0 0 0 2.458-2.458V7.86a1.04 1.04 0 1 0-2.08 0v4.964a.378.378 0 0 1-.378.378H2.777a.378.378 0 0 1-.378-.378V2.898c0-.1.04-.197.11-.268Zm13.058.294a1.04 1.04 0 0 0-1.47-1.471L7.741 7.808 6.35 6.417a1.04 1.04 0 0 0-1.47 1.47l2.126 2.128a1.04 1.04 0 0 0 1.471 0l7.091-7.091Z"
        fill={color}
      />
    </Svg>
  );
}
