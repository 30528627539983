import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ForwardButtonArrow = ({ color = '#fff', ...rest }: SvgProps) => (
  <Svg width={16} height={12} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5h12L8.5 1.5 10 0l6 6-6 6-1.5-1.5L12 7H0V5Z"
      fill={color}
    />
  </Svg>
);

export const BackButtonArrow = ({ color = '#fff', ...rest }: SvgProps) => (
  <Svg width={17} height={12} fill="none" {...rest}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M16.5 5h-12L8 1.5 6.5 0l-6 6 6 6L8 10.5 4.5 7h12V5Z"
      clipRule="evenodd"
    />
  </Svg>
);
