import React from 'react';
import { Star } from '../../assets';
import { Pressable, View, StyleSheet } from 'react-native';

interface StarRatingProps {
  /**
   * Max number of stars to rate
   */
  numberOfStars?: number;
  /**
   * User's rating
   */
  starRating: number;
  /**
   * Function to set user's rating
   */
  setStarRating: (starRating: number) => void;
}
const starRatingStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});

export const StarRating = ({
  numberOfStars = 5,
  starRating,
  setStarRating,
}: StarRatingProps) => {
  return (
    <View style={starRatingStyles.container}>
      {Array.from(Array(numberOfStars)).map((_, index) => {
        return (
          <Pressable
            accessibilityRole="button"
            onPress={() => {
              setStarRating(index);
            }}
            key={index}
          >
            <Star
              filled={index <= starRating}
              testID={
                index <= starRating
                  ? `filled-star-${index}`
                  : `empty-star-${index}`
              }
            />
          </Pressable>
        );
      })}
    </View>
  );
};
