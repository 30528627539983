import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const AccountIcon = ({
  width = 20,
  height = 20,
  color = '#636567',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.48 4.48 0 10 0s10 4.48 10 10-4.48 10-10 10S0 15.52 0 10Zm13-3c0-1.66-1.34-3-3-3S7 5.34 7 7s1.34 3 3 3 3-1.34 3-3Zm-9.5 8c1.29 1.848 4 3 6.5 3s5.21-1.152 6.5-3c-.03-1.895-4.51-3-6.5-3-2 0-6.47 1.105-6.5 3Z"
      fill={color}
    />
  </Svg>
);
