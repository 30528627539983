import React from 'react';
import { StyleSheet, View } from 'react-native';
import { StyledText } from '../Typography/StyledText';
import { useTheme } from '../../themes';
import { InfoContainerItemProps } from '../InfoContainerItem';
import { InformationCardItem } from '../InfoContainer';

const createStyles = (item: InformationCardItem) =>
  StyleSheet.create({
    cardIconContainer: {
      marginRight: 16,
    },
    cardContentContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 0.5,
    },
    cardInfoDecoratorContainer: {
      flex: 0.5,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    informationCard: {
      flexDirection: 'row',
      marginVertical: 14,
      paddingLeft: 16,
      marginLeft: 16,
    },
    cardValueContainer: {
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    rightDecoratorContainer: {
      justifyContent: 'center',
    },
    itemContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textContainer: {
      flexDirection: item.secondaryText ? 'column' : 'row',
    },
  });

export const ItemDisplay = ({
  item,
  index,
}: Omit<InfoContainerItemProps, 'itemsLength'>) => {
  const theme = useTheme();
  const styles = createStyles(item);
  const {
    disabled,
    content,
    value,
    Icon,
    RightDecorator,
    informationCardStyle,
    secondaryText,
    contentContainerStyle,
    decoratorContainerStyle,
  } = item;
  return (
    <View style={[styles.itemContainer, informationCardStyle]}>
      <View
        style={[
          styles.cardContentContainer,
          contentContainerStyle ? contentContainerStyle : null,
        ]}
      >
        {Icon ? (
          <View style={styles.cardIconContainer}>
            {disabled ? (
              <Icon color={theme.disabled.main} />
            ) : (
              <Icon color={theme.text.main} />
            )}
          </View>
        ) : null}
        <View style={{ flexDirection: secondaryText ? 'column' : 'row' }}>
          <StyledText
            textStyle={
              disabled
                ? theme.textStyles.disabled
                : theme.textStyles.header1Bold
            }
          >
            {content}
          </StyledText>
          {item.secondaryText ? (
            <StyledText textStyle={theme.textStyles.bodyMedium}>
              {secondaryText}
            </StyledText>
          ) : null}
        </View>
      </View>
      <View
        style={[
          styles.cardInfoDecoratorContainer,
          decoratorContainerStyle ? decoratorContainerStyle : null,
        ]}
      >
        <View style={styles.cardValueContainer}>
          <StyledText>{value ? value() : null}</StyledText>
        </View>

        {RightDecorator ? (
          <View
            style={styles.rightDecoratorContainer}
            testID={`right-decorator-${index}`}
          >
            {disabled ? (
              <RightDecorator color={theme.disabled.main} />
            ) : (
              <RightDecorator />
            )}
          </View>
        ) : null}
      </View>
    </View>
  );
};
