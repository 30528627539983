import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const MotionIcon = ({
  height = 22,
  width = 22,
  color = '#303030',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.994 4.872a2.145 2.145 0 1 1-4.173-.99 2.145 2.145 0 0 1 4.173.99Zm1.805.429a4 4 0 0 1-5.236 2.843l-.19.374 1.836 1.75h3.383v2h-4.183l-2.052-1.956-1.235 2.054 2.743 3.852-1.283 5.406-1.946-.462 1.074-4.523-2.055-2.884-3.552 2.189L4.07 13.81l.781-1.842 4.08 1.73 3.263-2.01 1.554-2.584-1.73-.944-3.89 1.5-.72-1.867 4.756-1.833 2.555 1.396.176-.346A4 4 0 1 1 21.8 5.3ZM6.092 9.56h-6v-2h6v2Zm-4-3h4v-2h-4v2Z"
      fill={color}
    />
  </Svg>
);
