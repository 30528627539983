import React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';

export function CheckmarkBox({ testID, color = '#221F20' }: SvgProps) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" testID={testID}>
      <Rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke={color} />
      <Path
        d="M3.5 8.5L5.5 11.5L12.5 4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
