import { StyleSheet, View } from 'react-native';
import React from 'react';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';
import { ButtonRules, StyledButton } from '../Button';

export interface FooterProps {
  pressBack?: () => void | Promise<void>;
  pressForward: () => void;
  disabledForward?: boolean;
  isForwardLoading?: boolean | undefined;
  forwardButtonText?: string;
  backButtonText?: string;
  backArrowEnabled?: boolean | undefined;
  areDualButtons?: boolean | undefined;
  showForwardArrowButton?: boolean | undefined;
  leftIcon?: any;
}

const createStyle = (
  theme: ComponentTheme,
  dualButton: boolean | undefined,
) => {
  const styles = StyleSheet.create({
    button: {
      flex: dualButton ? 0.47 : 1,
      height: 56,
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: theme.background.surface,
      borderRadius: theme.radii.radiusLarge,
    },
    footerBackgroundContainer: {
      width: '100%',
      paddingTop: '5%',
      paddingBottom: '8%',
      paddingHorizontal: '5%',
      backgroundColor: theme.background.surface,
    },
  });
  return styles;
};

export function Footer({
  pressBack = () => {},
  pressForward,
  disabledForward,
  isForwardLoading = false,
  forwardButtonText = 'Next',
  backButtonText = 'Back',
  backArrowEnabled = true,
  areDualButtons = true,
  showForwardArrowButton = true,
  leftIcon = null,
}: FooterProps) {
  const theme = useTheme();
  const styles = createStyle(theme, areDualButtons);
  return (
    <>
      <View style={styles.footerBackgroundContainer}>
        <View style={styles.buttonContainer}>
          {areDualButtons && (
            <StyledButton
              testID="back-button"
              style={styles.button}
              onPress={pressBack}
              backArrowButton={backArrowEnabled}
              buttonRules={ButtonRules.primaryB}
            >
              {backButtonText ? backButtonText : 'Back'}
            </StyledButton>
          )}
          <StyledButton
            testID="next-button"
            style={styles.button}
            onPress={pressForward}
            forwardArrowButton={showForwardArrowButton}
            disabled={disabledForward}
            isLoading={isForwardLoading}
            accessibilityRole="button"
            leftIcon={leftIcon !== null ? leftIcon : null}
          >
            {forwardButtonText ? forwardButtonText : 'Next'}
          </StyledButton>
        </View>
      </View>
    </>
  );
}
