import Svg, { Path, SvgProps } from 'react-native-svg';
import React from 'react';
export const Pen = ({
  height,
  width,
  fill = '#656565',
  ...props
}: SvgProps) => (
  <Svg viewBox="0 0 16 16" width={width} height={height} fill="none" {...props}>
    <Path
      fill={fill}
      fillRule="evenodd"
      d="m14.75 4.753.75-.75c.5-.5.903-2.2-.207-3.294C14.2-.401 12.5.003 12 .503l-.75.75 3.5 3.5Zm-4.25-2.75-9 9 3.5 3.5 9-9-3.5-3.5ZM.745 15.986 4 15.003l-3-3-.982 3.255a.598.598 0 0 0 .727.728Z"
      clipRule="evenodd"
    />
  </Svg>
);
