import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export const DeviceSkinny = ({
  width = 24,
  height = 10,
  color = '#F50',
  testID = 'deviceSkinnyIcon',
  ...props
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" testID={testID} {...props}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M3 0h18s3 0 3 3.5S21 7 21 7H3S0 7 0 3.5 3 0 3 0Zm.5 2h2S7 2 7 3.5 5.5 5 5.5 5h-2S2 5 2 3.5 3.5 2 3.5 2Zm17 0h-2S17 2 17 3.5 18.5 5 18.5 5h2S22 5 22 3.5 20.5 2 20.5 2ZM3 9V8h18v1s0 1-1 1H4c-1 0-1-1-1-1Zm11-5.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-1 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      clipRule="evenodd"
    />
  </Svg>
);
