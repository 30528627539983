import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export const BatteryLow = ({
  width = 24,
  height = 14,
  color = '#F50',
  testID = 'batteryLowIcon',
  ...props
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" testID={testID} {...props}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M3 0S0 0 0 3v8c0 3 3 3 3 3h15s3 0 3-3V3c0-3-3-3-3-3H3Zm0 1S1 1 1 3v8c0 2 2 2 2 2h15s2 0 2-2V3c0-2-2-2-2-2H3ZM2 3c0-1 1-1 1-1h1s1 0 1 1v8c0 1-1 1-1 1H3s-1 0-1-1V3Zm22 4c0 1.746-1.182 3.322-3 4V3c1.818.678 3 2.254 3 4Z"
      clipRule="evenodd"
    />
  </Svg>
);
