import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../SvgProps';

export function CheckmarkWideIcon({
  testID,
  color = '#5A8445',
}: SvgProps): JSX.Element {
  return (
    <Svg width={24} height={24} fill="none" testID={testID}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="m6 10 4 4 8-8 2 2-10 10-6-6 2-2Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
