import React from 'react';
import {DimensionValue, StyleSheet, View} from 'react-native';
import { useTheme } from '../../themes';
import { StyledText } from '../Typography/StyledText';
import {ComponentTheme} from "../../themes/defaultTheme";

export interface PercentTrackerProps {
  percent: number;
  header: string;
}

const createStyles = (theme :ComponentTheme, percentString: DimensionValue) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    contentContainer: {
      flexDirection: 'row',
      paddingBottom: 20,
      paddingTop: 20,
    },
    header: {
      flex: 8,
      paddingLeft: 15,
    },
    percent: {
      display: 'flex',
      flex: 2,
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
      paddingRight: 15,
      textAlign: 'right',
    },
    percentBox: {
      borderColor: 'red',
      borderWidth: 2,
      display: 'flex',
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
    },
    progressBar: {
      width: '100%',
      height: 4,
      backgroundColor: theme.secondary.darker,
    },
    progressBarFill: {
      height: 4,
      width: percentString,
      backgroundColor: theme.primary.main,
    },
  });

export function PercentTracker({ header, percent }: PercentTrackerProps) {
  const theme = useTheme();
  const wholePercentString = `${percent}%`;
  const styles = createStyles(theme, wholePercentString as DimensionValue);

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <StyledText
          style={styles.header}
          textStyle={theme.textStyles.header2Bold}
        >
          {header}
        </StyledText>
        <StyledText
          style={styles.percent}
          textStyle={theme.textStyles.header2Medium}
        >
          {percent}%
        </StyledText>
      </View>
      <View style={styles.progressBar} testID="progress-bar">
        <View
          style={styles.progressBarFill}
          testID={`progress-bar-fill-${percent}`}
        ></View>
      </View>
    </View>
  );
}
