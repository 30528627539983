import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export function UnselectedCheckBox({
  testID,
  color = '#2B2220',
  ...props
}: SvgProps) {
  return (
    <Svg width={15} height={15} fill="none" {...props} testID={testID}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.32 2.94h-10v10h10v-10Zm-10-2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-10a2 2 0 0 0-2-2h-10Z"
        fill={color}
      />
    </Svg>
  );
}
