import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const IntrusionIcon = ({
  height = 24,
  width = 21,
  color = '#303030',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.272.962c-.81-1.283-2.733-1.283-3.544 0-.495.785-1.117 1.703-1.72 2.414a5.9 5.9 0 0 1-.8.81c-.15.117-.231.154-.254.164-.681-.004-1.722-.112-2.64-.228a63.156 63.156 0 0 1-1.53-.213l-.096-.015-.031-.005L.5 3.707v12.338a2.98 2.98 0 0 0 1.392 2.52L10.5 24l8.608-5.436a2.98 2.98 0 0 0 1.392-2.519V3.707l-1.157.183-.031.004-.095.015a55.02 55.02 0 0 1-1.53.213c-.919.116-1.96.224-2.64.229-.024-.01-.105-.048-.254-.165a5.897 5.897 0 0 1-.801-.81c-.603-.711-1.225-1.629-1.72-2.414ZM2.5 16.045V6.022l.562.073c.935.118 2.117.245 2.938.245.594 0 1.103-.323 1.445-.591.378-.296.75-.686 1.094-1.092.69-.816 1.37-1.825 1.883-2.637a.048.048 0 0 1 .02-.019.128.128 0 0 1 .058-.012c.025 0 .045.006.057.012.01.005.015.01.02.02.513.81 1.193 1.82 1.884 2.636.344.406.716.796 1.094 1.092.342.268.851.591 1.445.591.82 0 2.003-.127 2.939-.245l.561-.073v10.023c0 .34-.175.657-.464.84L10.5 21.644l-7.536-4.76a.993.993 0 0 1-.464-.839Zm7-1.18V6.908h2v7.957h-2Zm0 3.978v-1.989h2v1.99h-2Z"
      fill={color}
    />
  </Svg>
);
