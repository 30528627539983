import { TextStyle } from 'react-native';
import { Colors } from './colors';
import { FontFamilies } from './fonts';

export type TextStylesType = {
  header6Bold: TextStyle;
  header6Italic: TextStyle;
  header6Medium: TextStyle;
  header5Bold: TextStyle;
  header5Italic: TextStyle;
  header5Medium: TextStyle;
  header4Bold: TextStyle;
  header4Italic: TextStyle;
  header4Medium: TextStyle;
  header3Bold: TextStyle;
  header3Italic: TextStyle;
  header3Medium: TextStyle;
  header2Bold: TextStyle;
  header2Italic: TextStyle;
  header2Medium: TextStyle;
  header1Bold: TextStyle;
  header1Italic: TextStyle;
  header1Medium: TextStyle;
  bodySemiBold: TextStyle;
  bodyMedium: TextStyle;
  disabled: TextStyle;
  errorText: TextStyle;
  formLabel: TextStyle;
};

export const TextStyles: TextStylesType = {
  header6Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 54,
    fontWeight: '700',
    lineHeight: 72,
    color: Colors.darkGreyRegular4,
  },
  header6Italic: {
    fontFamily: FontFamilies.headerItalic,
    fontSize: 54,
    fontWeight: '400',
    lineHeight: 72,
    color: Colors.darkGreyRegular6,
  },
  header6Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 54,
    fontWeight: '400',
    lineHeight: 72,
    color: Colors.darkGreyRegular6,
  },
  header5Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 42,
    fontWeight: '700',
    lineHeight: 58,
    color: Colors.darkGreyRegular4,
  },
  header5Italic: {
    fontFamily: FontFamilies.headerItalic,
    fontSize: 42,
    fontWeight: '400',
    lineHeight: 58,
    color: Colors.darkGreyRegular6,
  },
  header5Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 42,
    fontWeight: '400',
    lineHeight: 58,
    color: Colors.darkGreyRegular6,
  },
  header4Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 46,
    color: Colors.darkGreyRegular4,
  },
  header4Italic: {
    fontFamily: FontFamilies.headerItalic,
    fontSize: 32,
    fontWeight: '400',
    lineHeight: 46,
    color: Colors.darkGreyRegular6,
  },
  header4Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 32,
    fontWeight: '400',
    lineHeight: 46,
    color: Colors.darkGreyRegular6,
  },
  header3Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 36,
    color: Colors.darkGreyRegular4,
  },
  header3Italic: {
    fontFamily: FontFamilies.headerItalic,
    fontSize: 24,
    fontWeight: '400',
    lineHeight: 36,
    color: Colors.darkGreyRegular6,
  },
  header3Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 24,
    fontWeight: '400',
    lineHeight: 36,
    color: Colors.darkGreyRegular6,
  },
  header2Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 20,
    fontWeight: '700',
    lineHeight: 30,
    color: Colors.darkGreyRegular4,
  },
  header2Italic: {
    fontFamily: FontFamilies.headerItalic,
    fontSize: 20,
    fontWeight: '400',
    lineHeight: 30,
    color: Colors.darkGreyRegular6,
  },
  header2Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 20,
    fontWeight: '400',
    lineHeight: 30,
    color: Colors.darkGreyRegular6,
  },
  header1Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 24,
    color: Colors.darkGreyRegular4,
  },
  header1Italic: {
    fontFamily: FontFamilies.headerItalic,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: Colors.darkGreyRegular6,
  },
  header1Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: Colors.darkGreyRegular6,
  },
  bodySemiBold: {
    fontFamily: FontFamilies.bodySemiBold,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: Colors.darkGreyRegular4,
  },
  bodyMedium: {
    fontFamily: FontFamilies.body,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: Colors.darkGreyRegular6,
  },
  disabled: {
    fontFamily: FontFamilies.body,
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 24,
    color: Colors.lightGreyRegularE,
  },
  errorText: {
    fontFamily: FontFamilies.body,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: Colors.trafficLightRed,
  },
  formLabel: {
    fontFamily: FontFamilies.body,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    textTransform: 'uppercase',
    color: Colors.darkGreyRegular4,
  },
};

export const TextStylesDark: TextStylesType = {
  header6Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 54,
    fontWeight: '700',
    lineHeight: 72,
    color: Colors.white,
  },
  header6Italic: {
    ...TextStyles.header6Italic,
    color: Colors.lightGreyRegularB,
  },
  header6Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 54,
    fontWeight: '400',
    lineHeight: 72,
    color: Colors.lightGreyRegularB,
  },
  header5Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 42,
    fontWeight: '700',
    lineHeight: 58,
    color: Colors.white,
  },
  header5Italic: {
    ...TextStyles.header5Italic,
    color: Colors.lightGreyRegularB,
  },
  header5Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 42,
    fontWeight: '400',
    lineHeight: 58,
    color: Colors.lightGreyRegularB,
  },
  header4Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 46,
    color: Colors.white,
  },
  header4Italic: {
    ...TextStyles.header4Italic,
    color: Colors.lightGreyRegularB,
  },
  header4Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 32,
    fontWeight: '400',
    lineHeight: 46,
    color: Colors.lightGreyRegularB,
  },
  header3Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 36,
    color: Colors.white,
  },
  header3Italic: {
    ...TextStyles.header3Italic,
    color: Colors.lightGreyRegularB,
  },
  header3Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 24,
    fontWeight: '400',
    lineHeight: 36,
    color: Colors.lightGreyRegularB,
  },
  header2Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 20,
    fontWeight: '700',
    lineHeight: 30,
    color: Colors.white,
  },
  header2Italic: {
    ...TextStyles.header2Italic,
    color: Colors.lightGreyRegularB,
  },
  header2Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 20,
    fontWeight: '400',
    lineHeight: 30,
    color: Colors.lightGreyRegularB,
  },
  header1Bold: {
    fontFamily: FontFamilies.headerBold,
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 24,
    color: Colors.white,
  },
  header1Italic: {
    ...TextStyles.header1Italic,
    color: Colors.lightGreyRegularB,
  },
  header1Medium: {
    fontFamily: FontFamilies.header,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: Colors.lightGreyRegularB,
  },
  bodySemiBold: {
    fontFamily: FontFamilies.bodySemiBold,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: Colors.white,
  },
  bodyMedium: {
    fontFamily: FontFamilies.body,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: Colors.lightGreyRegularB,
  },
  disabled: {
    fontFamily: FontFamilies.body,
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 24,
    color: Colors.darkGreyRegular4,
  },
  errorText: {
    fontFamily: FontFamilies.body,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: Colors.darkModeTrafficLightRed,
  },
  formLabel: {
    fontFamily: FontFamilies.body,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: Colors.lightGreyRegularB,
    textTransform: 'uppercase',
  },
};
