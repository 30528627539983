import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export function OutgoingArrow({ width = 24, height = 24, ...props }: SvgProps) {
  return (
    <Svg width={width} height={height} fill="none" {...props}>
      <Path
        fill="#F50"
        fillRule="evenodd"
        d="M20 5.414 9.414 16 8 14.586 18.586 4H14V2h8v8h-2V5.414ZM4 9v10s0 1 1 1h10c1 0 1-1 1-1v-5h2v6s0 2-2 2H4c-2 0-2-2-2-2V8s0-2 2-2h6v2H5C4 8 4 9 4 9Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
