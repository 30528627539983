import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const PhotoIcon = ({ color = '#fff', ...props }: SvgProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    accessibilityLabel="Photo Icon"
    accessibilityHint="Describes Photo Action"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm-5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      fill={color}
    />
    <Path d="M19 10.5h2v-2h-2v2Z" fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.5h6l1.5 2.333H22a2 2 0 0 1 2 2V19.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6.833a2 2 0 0 1 2-2h5.5L9 2.5Zm6.408 4.333-1.5-2.333h-3.816l-1.5 2.333H2V19.5h20V6.833h-6.592Z"
      fill={color}
    />
  </Svg>
);
