import React, {
  MutableRefObject,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { StyledButton } from '../Button';
import { TextInput, View } from 'react-native';
import { Condition } from './types';
import { DropDown } from '../DropDown';
import { createStyles } from './styles';
import { StyledText } from '../Typography/StyledText';
import { useTheme } from '../../themes';
import { FormTextInput } from '../FormTextInput';
import { FormLabel } from '../FormLabel';
import { FormError } from '../FormError';
import { InputTextProps } from '../InputText';
import { CheckmarkIcon, DisabledIcon } from '../../assets';
import { ShowHideIcon } from '../ShowHideIcon';

type Validator = {
  (input: string): string;
};

export interface Field {
  defaultValue?: string;
  validator: Validator;
  isShowHideField?: boolean;
  isDropDown?: boolean;
  isHidden?: boolean;
  renderTopRightButton?: () => JSX.Element;
  onChangeValidator?: (input: string) => Condition[];
  label?: string;
  inputProps: InputTextProps;
  ref?: RefObject<TextInput>;
}

export interface FieldState {
  value: string;
  isHidden: boolean;
  isDropDown: boolean;
  dropDownItems: string[];
  errorMessage: string;
  conditions: Condition[];
}

export type FormState = { [key: string]: FieldState };

export interface FormProps {
  fields: { [key: string]: Field };
  /**
   *  Callback to execute when form is submitted.
   *  @param values object where key is fieldName and value is user inputted
   */
  action: (values: Record<string, string>) => void;
  /**
   *  The text on the submit button.
   */
  submitText?: string;
  /**
   *  Ref object passed down when submit button is not used from Form Component
   *  @example  const submitValuesRef: React.RefObject<Function> = React.createRef();
   */
  submitValuesRef?: MutableRefObject<Function>;
}

export function Form({
  fields,
  action,
  submitText,
  submitValuesRef,
}: FormProps): JSX.Element {
  const theme = useTheme();
  const styles = createStyles(theme);
  const getInitialValues = (fieldKeys: string[]): FormState => {
    const state: { [key: string]: FieldState } = {};
    fieldKeys.forEach((key) => {
      state[key] = {
        value: fields[key].defaultValue || '',
        errorMessage: '',
        isDropDown: fields[key].isDropDown ? true : false,
        dropDownItems: fields[key].isDropDown
          ? [...(fields[key].inputProps as Record<string, string[]>).items]
          : [],
        isHidden: fields[key].isShowHideField ? true : false,
        conditions: [],
      };
    });

    return state;
  };

  const fieldKeys = Object.keys(fields);
  const [fieldStates, setFieldStates] = useState(getInitialValues(fieldKeys));
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [toggleInputType, setToggleInputType] = useState({});
  useEffect(() => {
    Object.keys(fieldStates).map((fieldState) => {
      if (fieldStates[fieldState].isHidden) {
        setToggleInputType((prev) => ({
          ...prev,
          [fieldState]: true,
        }));
      }
    });
  }, [Object.keys(fieldStates).length]);
  const onChangeValue = (key: string, value: string): void => {
    const field = fields[key];
    const newState = {
      ...fieldStates,
      [key]: {
        ...fieldStates[key],
        value,
        errorMessage: '',
        conditions: field.onChangeValidator
          ? field.onChangeValidator(value)
          : [],
      },
    };

    setFieldStates(newState);
    setErrorMessage('');
  };

  const onShowHidePress = useCallback(
    (field: string) =>
      setToggleInputType((prev) => ({
        ...prev,
        // @ts-ignore
        [field]: !prev[field],
      })),
    [],
  );
  const addFieldErrorMessage = (
    initialState: FormState,
    key: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    errorMessage: string,
  ): FormState => {
    return { ...initialState, [key]: { ...fieldStates[key], errorMessage } };
  };

  const getValues = (): Record<string, string> => {
    return fieldKeys.reduce(
      (accumlated, currentFieldKey) => ({
        ...accumlated,
        [currentFieldKey]: fieldStates[currentFieldKey].value,
      }),
      {},
    );
  };

  const conditionsAllMet = (conditions: Condition[]): boolean => {
    return conditions
      .map((condition: Condition) => condition.isMet)
      .reduce(
        (previousValue: boolean, currentValue: boolean): boolean =>
          previousValue && currentValue,
      );
  };

  const submitValues = async (): Promise<void> => {
    let isValidForm = true;
    let stateWithErrorMessages: FormState = fieldStates;
    fieldKeys.forEach((key: string) => {
      const field = fields[key];
      if (!field.isHidden) {
        const isValid = field.validator(fieldStates[key].value);
        const onChangeValid = field.onChangeValidator
          ? conditionsAllMet(field.onChangeValidator(fieldStates[key].value))
          : true;
        if (isValid !== '') {
          stateWithErrorMessages = addFieldErrorMessage(
            stateWithErrorMessages,
            key,
            isValid,
          );
          isValidForm = false;
        } else if (!onChangeValid) {
          stateWithErrorMessages = addFieldErrorMessage(
            stateWithErrorMessages,
            key,
            'Field must match requirements',
          );
          isValidForm = false;
        }
      }
    });

    if (isValidForm) {
      const values = getValues();
      try {
        setIsLoading(true);
        await action(values);
      } catch (error: any) {
        setErrorMessage(error?.message ?? '');
      } finally {
        setIsLoading(false);
      }
    } else {
      setFieldStates(stateWithErrorMessages);
    }
  };

  function displayErrorMessage(): ReactElement {
    return (
      <>
        <StyledText textStyle={theme.textStyles.errorText}>
          {errorMessage !== '' ? errorMessage : ' '}
        </StyledText>
      </>
    );
  }

  useEffect(() => {
    if (submitValuesRef) {
      submitValuesRef.current = submitValues;
    }
  });

  let dropdownZIndex =
    fieldKeys
      .map((fieldKey) => fields[fieldKey])
      .filter((field) => field.isDropDown).length + 1;
  return (
    <>
      <View style={[styles.formBorder, { zIndex: 1 }]} role="form">
        {fieldKeys.map((key: string) => {
          const field = fields[key];
          const { isShowHideField } = field;

          if (!field.isHidden) {
            return (
              <View
                key={key}
                style={[
                  styles.formView,
                  { zIndex: field.isDropDown ? dropdownZIndex-- : 0 },
                ]}
              >
                {field.isDropDown ? (
                  <>
                    <FormLabel label={field.label} />
                    <DropDown
                      placeholderText={
                        (fields[key].inputProps as Record<string, string>)
                          .placeholder
                      }
                      testID={
                        (fields[key].inputProps as Record<string, string>)
                          .testID
                      }
                      items={fieldStates[key].dropDownItems}
                      selectedValue={fields[key].defaultValue}
                      onValueChange={(item: string) => onChangeValue(key, item)}
                    />
                    <FormError
                      errorMessage={fieldStates[key].errorMessage}
                      isInErrorState={fieldStates[key].errorMessage !== ''}
                    />
                  </>
                ) : (
                  <FormTextInput
                    label={field.label}
                    key={key}
                    fieldStateValue={fieldStates[key].value}
                    textInputProps={{
                      testID: (field?.inputProps as Record<string, string>)
                        ?.testID,
                      ...(field?.inputProps as Record<string, string>),
                      autoCapitalize: 'none',
                      autoComplete: 'off',
                      autoCorrect: false,
                      placeholder: (field?.inputProps as Record<string, string>)
                        ?.placeholder,
                      placeholderTextColor: theme.text.placeholder,
                      // @ts-ignore
                      secureTextEntry: toggleInputType[key],
                      onSubmitEditing: submitValues,
                      onChangeText: (text: string): void =>
                        onChangeValue(key, text),

                      leftIcon:
                        field?.inputProps?.leftIcon &&
                        field.inputProps.leftIcon,
                      rightIcon: () => {
                        return isShowHideField ? (
                          <ShowHideIcon onPress={() => onShowHidePress(key)} />
                        ) : undefined;
                      },
                    }}
                    renderTopRightButton={field?.renderTopRightButton}
                    isInErrorState={fieldStates[key].errorMessage !== ''}
                    errorMessage={fieldStates[key].errorMessage}
                    ref={field?.ref}
                  />
                )}
                {fieldStates[key].conditions.map(
                  (condition: Condition, index: number) => {
                    return (
                      <View key={index} style={styles.conditionViewContainer}>
                        <View style={styles.conditionIcon}>
                          {condition.isMet ? (
                            <CheckmarkIcon
                              testID={`check-${index}`}
                              color={theme.alerts.success}
                            />
                          ) : (
                            <DisabledIcon testID={`cross-${index}`} />
                          )}
                        </View>
                        <View style={styles.conditionText}>
                          <StyledText textStyle={theme.textStyles.bodyMedium}>
                            {condition.name}
                          </StyledText>
                        </View>
                      </View>
                    );
                  },
                )}
              </View>
            );
          }
        })}
      </View>
      {errorMessage !== '' && displayErrorMessage()}
      {submitText !== undefined && (
        <View style={styles.buttonContainer}>
          <StyledButton
            onPress={submitValues}
            isLoading={isLoading}
            disabled={isLoading}
            testID="submit-button"
            style={styles.submitButton}
          >
            {submitText}
          </StyledButton>
        </View>
      )}
    </>
  );
}
