import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export function DropDownArrow({
  testID,
  color = '#767676',
  ...rest
}: SvgProps): JSX.Element {
  return (
    <Svg
      width="18"
      height="10"
      fill="none"
      testID={testID}
      {...rest}
    >
      <Path
        d="M1 1.5L8.80488 8.5L17 1.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
