import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ShowToastProps, ToastContextProps } from './types';
import { ToastContainer } from './ToastComponents/ToastContainer';

const initialState = {
  id: '',
  isToastShown: false,
  onPress: () => {},
  title: '',
  body: '',
  isToastCloseable: false,
};
const initialProps = {
  showToast: () => undefined,
  hideToast: () => undefined,
  toastData: [initialState],
};
const ToastContext = createContext<ToastContextProps>(initialProps);
const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toastData, setToastData] = useState<ShowToastProps[]>([]);

  const hideToast = (id: string) => {
    setToastData((prevState) => {
      return prevState.map((state) => {
        if (state.id === id) {
          return { ...state, isToastShown: false };
        } else {
          return state;
        }
      });
    });
  };

  const showToast = (prop: Omit<ShowToastProps, 'isToastShown'>) => {
    setToastData((prevState) => {
      const existingIndex = prevState.findIndex(
        (state) => state.id === prop.id,
      );
      if (existingIndex !== -1) {
        const newData = { ...prevState[existingIndex], isToastShown: true };
        return [
          ...prevState.filter((data) => data.id !== prop.id),
          { ...newData },
        ];
      }
      return [...prevState, { ...prop, isToastShown: true }];
    });
  };

  return (
    <ToastContext.Provider
      value={{
        toastData,
        showToast,
        hideToast,
      }}
    >
      {children}
      <ToastContainer toastData={toastData} />
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toastContext = useContext(ToastContext);
  if (!toastContext) {
    throw new Error(`Toast Context not found! Add <ToastContext> as parent`);
  }
  return toastContext;
};

export { ToastContext, ToastProvider, useToast };
