import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  FlatList,
  View,
  ViewStyle,
  StyleSheet,
} from 'react-native';
import { FilledBubble, EmptyBubble } from '../../assets';

export interface CarouselProps {
  /**
   * An array of component objects where each object will be mapped out into its own slide.
   */
  CarouselObjects: { component: JSX.Element }[];

  /**
   * There are X number of bubbles for X number of CarouselObjects. This will display the small hexagons (bubbles) that indicate which CarouselObject (slide) the user is on.
   */
  BubbleIndicatorPresent?: boolean;

  /**
   * Intended to allow customization of the bubble positioning or other ViewStyle props.
   */
  BubbleOptions?: { positioning?: ViewStyle };

  /**
   * If enabled, the carousel will automatically scroll to the next object (and restart scrolling from the first object) after a set time.
   */
  automaticScroll?: boolean;

  /**
   * If automaticScroll is enabled, this is the time it takes for the carousel to automatically switch to the next object. If a user interacts with the presented CarouselObject, the carousel will stop auto scrolling. The timer will restart once the user stops the interaction.
   */
  automaticScrollInterval?: number;
}

interface BubbleIndicatorProps extends CarouselProps {
  bubbleIndex: number;
}

function BubbleIndicators({
  CarouselObjects,
  BubbleOptions,
  bubbleIndex,
}: BubbleIndicatorProps) {
  return (
    <View
      style={
        BubbleOptions?.positioning ?? {
          position: 'absolute',
          alignSelf: 'center',
          bottom: '5%',
          margin: 'auto',
          flexDirection: 'row',
        }
      }
    >
      {CarouselObjects.map((_, index) => {
        return (
          <View key={index} style={{ padding: 10 }}>
            {bubbleIndex === index ? (
              <FilledBubble testID={`bubble-active-${index}`} />
            ) : (
              <EmptyBubble testID={`bubble-${index}`} />
            )}
          </View>
        );
      })}
    </View>
  );
}

const { width } = Dimensions.get('window');

const CarouselStyles = StyleSheet.create({
  flatlist: {
    maxWidth: '100%',
  },
  container: {
    width: width,
    overflow: 'hidden',
  },
});

export function Carousel({
  CarouselObjects,
  BubbleIndicatorPresent,
  BubbleOptions,
  automaticScroll,
  automaticScrollInterval,
}: CarouselProps) {
  const ref = React.useRef<FlatList>(null);
  const [bubbleIndex, setBubbleIndex] = useState(0);
  const [autoScrollIndex, setAutoScrollIndex] = useState(0);
  const [userIsScrolling, setUserIsScrolling] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (automaticScroll && !userIsScrolling) {
        if (autoScrollIndex < CarouselObjects.length - 1) {
          ref?.current?.scrollToIndex({ index: autoScrollIndex + 1 });
          setAutoScrollIndex(autoScrollIndex + 1);
        } else {
          ref?.current?.scrollToIndex({ index: 0 });
          setAutoScrollIndex(0);
        }
      }
    }, automaticScrollInterval ?? 6000);
    return () => clearInterval(interval);
  }, [autoScrollIndex, userIsScrolling]);

  return (
    <>
      <FlatList
        ref={ref}
        testID="Carousel"
        pagingEnabled
        style={CarouselStyles.flatlist}
        //TODO: convert to useCallback
        onScroll={(event) => {
          if (
            BubbleIndicatorPresent &&
            event.nativeEvent.contentOffset.x >= 0
          ) {
            const currentIndex = Math.round(
              event.nativeEvent.contentOffset.x / width,
            );
            if (currentIndex < CarouselObjects.length) {
              setBubbleIndex(currentIndex);
              setAutoScrollIndex(currentIndex);
            }
          }
        }}
        getItemLayout={(_, index) => ({
          length: CarouselObjects.length,
          offset: width * index,
          index,
        })}
        onScrollBeginDrag={() => setUserIsScrolling(true)}
        onScrollEndDrag={() => setUserIsScrolling(false)}
        centerContent
        horizontal
        showsHorizontalScrollIndicator={false}
        data={CarouselObjects}
        keyExtractor={(_, index) => `${index}`}
        renderItem={({ item }) => (
          <View style={CarouselStyles.container}>{item.component}</View>
        )}
      />
      {BubbleIndicatorPresent && (
        <BubbleIndicators
          CarouselObjects={CarouselObjects}
          BubbleOptions={BubbleOptions}
          bubbleIndex={bubbleIndex}
        />
      )}
    </>
  );
}
