import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export function LEDLight({
  width = 24,
  height = 24,
  color = '#636567',
  testID = 'ledLight',
  ...props
}: SvgProps) {
  return (
    <Svg width={width} height={height} fill="none" testID={testID} {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H1Zm2 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill={color}
      />
    </Svg>
  );
}
