import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export const Info = ({
  width = 24,
  height = 24,
  color = '#FF5500',
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" color={color}>
    <Path
      fill="#F50"
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm1-9v6h-2v-6h2Zm0-2V7h-2v2h2Z"
      clipRule="evenodd"
    />
  </Svg>
);
