import React, { useState } from 'react';
import {
  ActivityIndicator,
  FlexStyle,
  StyleProp,
  Pressable,
  StyleSheet,
  ViewStyle,
  PressableProps,
  View,
  TextStyle,
  AccessibilityProps,
} from 'react-native';
import { useTheme } from '../../themes';
import { StyledText } from '../Typography/StyledText';
import { ComponentTheme } from '../../themes/defaultTheme';
import { BackButtonArrow, ForwardButtonArrow, Update } from '../../assets';
import { SvgProps } from 'react-native-svg';

export type StyledButtonProps = Omit<
  PressableProps,
  'onPress' | 'disabled' | 'children'
> &
  AccessibilityProps & {
    /**
     * Used to render button in disabled state.
     */
    disabled?: boolean;
    /**
     * Callback to execute when user interacts with button.
     */
    onPress: () => void | Promise<void>;
    /**
     * Used to display a loading indicator.
     */
    isLoading?: boolean;
    /**
     * Used to display text or react element.
     */
    children: string | JSX.Element;
    buttonRules?: ButtonRules;
    testID?: string;
    textStyling?: StyleProp<TextStyle> | undefined;
    style?: StyleProp<ViewStyle | FlexStyle>;
    accessibilityLabel?: string;
    accessibilityHint?: string;
    /**
     * A styled label that will be displayed before a non-string child component.
     */
    iconLabel?: string;
    /**
     * This will justify the content within the button.
     * Space between will spread the child components evenly in a row while center will place all components as close to the center of the button as possible.
     */
    buttonSpacing?: 'space-between' | 'center';
    /**
     * Used to change the look of the border radius. Square will produce a more square looking button, round will produce a more round looking button.
     */
    buttonRounding?: 'square' | 'round';
    /**
     * Displays an icon to the left of the input text
     */
    leftIcon?: ButtonIcon | undefined;
    /**
     * Displays a forward arrow SVG on the button.
     */
    forwardArrowButton?: boolean;
    /**
     * Displays a backward arrow SVG on the button.
     */
    backArrowButton?: boolean;
    /**
     * href prop to render button as link requires role set to link.
     */
    href?: string;
    hrefAttrs?: Record<string, string>;
  };

export enum ButtonRules {
  'primaryB' = 'primaryB',
  'primaryA' = 'primaryA',
  'secondary' = 'secondary',
  'tertiary' = 'tertiary',
  'quaternary' = 'quaternary',
}

export interface StylesProps {
  buttonRules: ButtonRules;
  isPressed: boolean;
  disabled: boolean;
  buttonRounding: 'square' | 'round';
  buttonSpacing: 'space-between' | 'center';
}

export enum ButtonIcon {
  UPDATE = 'UPDATE',
}

export function getButtonRules(
  buttonRules: ButtonRules,
  isPressed: boolean,
  disabled: boolean,
  theme: ComponentTheme,
): { button: ViewStyle; text: TextStyle } {
  if (isPressed || disabled) {
    return {
      button: {
        backgroundColor: theme.secondary.darker,
        borderColor: theme.secondary.darker,
      },
      text: {
        color: theme.secondary.main,
      },
    };
  } else if (buttonRules === ButtonRules.primaryA) {
    return {
      button: {
        backgroundColor: theme.primary.main,
        borderColor: theme.primary.main,
      },
      text: {
        color: theme.buttonText.main,
      },
    };
  } else if (buttonRules === ButtonRules.primaryB) {
    return {
      button: {
        backgroundColor: theme.secondary.main,
        borderColor: theme.primary.main,
      },
      text: {
        color: theme.buttonText.secondary,
      },
    };
  } else if (buttonRules === ButtonRules.secondary) {
    return {
      button: {
        backgroundColor: theme.secondary.main,
        borderColor: theme.primary.main,
      },
      text: {
        color: theme.buttonText.secondary,
      },
    };
  } else if (buttonRules === ButtonRules.tertiary) {
    return {
      button: {
        backgroundColor: theme.tertiary.main,
        borderColor: theme.tertiary.main,
      },
      text: {
        color: theme.buttonText.tertiary,
      },
    };
  } else {
    return {
      button: {
        backgroundColor: theme.quaternary.main,
        borderColor: theme.quaternary.secondary,
      },
      text: {
        color: theme.buttonText.quarternary,
      },
    };
  }
}

const getButtonIcon = (type: ButtonIcon, props: SvgProps) => {
  switch (type) {
    case ButtonIcon.UPDATE:
      return <Update {...props} />;
    default:
      return <></>;
  }
};

const createStyles = (
  theme: ComponentTheme,
  {
    buttonRules,
    isPressed,
    disabled,
    buttonRounding,
    buttonSpacing,
  }: StylesProps,
) =>
  StyleSheet.create({
    button: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      borderRadius: buttonRounding === 'square' ? theme.radii.radiusLarge : 64,
      borderWidth: 2,
      elevation: 3,
      marginRight: 2,
      paddingLeft: 20,
      paddingRight: 20,
      ...getButtonRules(buttonRules, isPressed, disabled, theme).button,
    },
    forwardArrow: {
      marginLeft: 10,
    },
    text: {
      ...getButtonRules(buttonRules, isPressed, disabled, theme).text,
      textAlign: 'center',
    },
    iconButtonStyle: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: buttonSpacing,
      alignItems: 'center',
    },
  });
/**
 * # Button
 * Pressable component that renders as `button` on web
 * */
export function StyledButton({
  disabled = false,
  onPress,
  style,
  isLoading = false,
  children,
  buttonRules = ButtonRules.primaryA,
  testID = 'canopy-button',
  textStyling,
  iconLabel,
  accessibilityLabel,
  accessibilityHint,
  buttonSpacing = 'center',
  buttonRounding = 'square',
  leftIcon = undefined,
  forwardArrowButton = false,
  backArrowButton = false,
  role = 'button',
  ...rest
}: StyledButtonProps) {
  const [isPressed, setIsPressed] = useState(false);

  const theme = useTheme();

  const styles = createStyles(theme, {
    buttonRules,
    isPressed,
    disabled,
    buttonRounding,
    buttonSpacing,
  });
  return (
    <Pressable
      style={[styles.button, style]}
      disabled={disabled || isLoading}
      onPress={onPress}
      onPressIn={(): void => setIsPressed(true)}
      onPressOut={(): void => setIsPressed(false)}
      testID={testID}
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      role={role}
      {...rest}
    >
      {isLoading ? (
        <ActivityIndicator
          size="large"
          color={
            buttonRules === ButtonRules.primaryA
              ? theme.primary.contrastText
              : theme.secondary.contrastText
          }
          testID="loading-indicator"
        />
      ) : (
        <>
          {typeof children === 'string' ? (
            <View
              style={
                forwardArrowButton || backArrowButton || leftIcon !== undefined
                  ? styles.iconButtonStyle
                  : {}
              }
            >
              {backArrowButton && (
                <View style={{ paddingRight: 10 }}>
                  <BackButtonArrow
                    color={
                      getButtonRules(buttonRules, isPressed, disabled, theme)
                        .text.color
                    }
                    testID="back-button-arrow-icon"
                  />
                </View>
              )}
              {leftIcon !== undefined && (
                <View style={{ paddingRight: 5 }}>
                  {getButtonIcon(leftIcon, {
                    color: getButtonRules(
                      buttonRules,
                      isPressed,
                      disabled,
                      theme,
                    ).text.color,
                    testID: 'button-left-icon',
                  })}
                </View>
              )}
              <StyledText
                style={[styles.text, textStyling]}
                textStyle={theme.textStyles.header1Bold}
              >
                {children}
              </StyledText>

              {forwardArrowButton && (
                <ForwardButtonArrow
                  style={styles.forwardArrow}
                  color={
                    getButtonRules(buttonRules, isPressed, disabled, theme).text
                      .color
                  }
                  testID="forward-button-arrow-icon"
                />
              )}
            </View>
          ) : iconLabel ? (
            <>
              <View style={styles.iconButtonStyle}>
                <StyledText
                  style={[styles.text, textStyling]}
                  textStyle={theme.textStyles.header1Bold}
                >
                  {iconLabel}
                </StyledText>
                {children}
              </View>
            </>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </Pressable>
  );
}
