type RadiiKeys = keyof typeof CornerRadii;
export type CornerRadiiType = {
  [key in RadiiKeys]: number;
};

enum CornerRadii {
  radiusSmall = 4,
  radiusMedium = 6,
  radiusLarge = 8,
  radiusXLarge = 12,
}

export default CornerRadii;
