import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export function MonitorIcon({
  width = 24,
  height = 24,
  testID = 'monitorIcon',
  color = '#636567',
  ...props
}: SvgProps) {
  return (
    <Svg width={width} testID={testID} height={height} fill="none" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M17 7v5c0 1.5 1.5 3 3 3v1H0v-1c1.5 0 3-1.5 3-3V7s0-7 7-7 7 7 7 7Zm-7 13c-2.143 0-3.994-1.215-5-3h10c-1.007 1.785-2.857 3-5 3Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
