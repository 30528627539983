/*
 see more information - https://github.com/adamldoyle/react-aws-auth-context
* */
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Auth } from 'aws-amplify';
import {
  AuthContextProps,
  AuthMode,
  AuthProviderProps,
  CompleteNewPasswordProps,
  InputProps,
  ResetPasswordProps,
} from './types';
import {
  authReducer,
  switchMode,
  updateResendConfirmationLoadingState,
  updateInitialLoadingState,
  updateLoadingState,
  updateSession,
  updateUser,
} from './reducer';

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);
const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, {
    session: null,
    user: null,
    isLoading: false,
    isResendConfirmationLoading: false,
    isInitialLoad: null,
    userName: '',
    authMode: AuthMode.SIGN_OUT,
  });
  const updateSessionInfo = async () => {
    try {
      const currentSession = await Auth.currentSession();
      dispatch(updateSession(currentSession));
    } catch (e) {
      dispatch(updateSession(null));
      throw e;
    }
  };
  const updateUserInfo = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      dispatch(updateUser(currentUser));
    } catch (e) {
      dispatch(updateUser(null));
      throw e;
    }
  };
  /*
   * on component mount if session exists and if so update state with user auth mode
   * */
  useEffect(() => {
    (async () => {
      try {
        dispatch(updateInitialLoadingState(true));
        await updateSessionInfo();
        await updateUserInfo();

        dispatch(switchMode(AuthMode.LOGGED_IN));
      } catch (e) {
        dispatch(switchMode(AuthMode.SIGN_OUT));
        dispatch(updateUser(null));
        dispatch(updateSession(null));
      } finally {
        dispatch(updateInitialLoadingState(false));
      }
    })();
  }, []);

  async function signIn(values: InputProps): Promise<void> {
    try {
      dispatch(updateLoadingState(true));
      const user = await Auth.signIn(values.email, values.password);
      await updateSessionInfo();
      dispatch(updateUser(user));
      if (user?.challengeName === AuthMode.NEW_PASSWORD_REQUIRED) {
        dispatch(switchMode(user?.challengeName));
      } else {
        dispatch(switchMode(AuthMode.LOGGED_IN));
      }
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateLoadingState(false));
    }
  }

  async function sendResetPasswordCode({
    email,
  }: Pick<InputProps, 'email'>): Promise<void> {
    try {
      dispatch(updateLoadingState(true));
      await Auth.forgotPassword(email);
      dispatch(switchMode(AuthMode.FORGOT_PASSWORD, email));
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateLoadingState(false));
    }
  }

  async function resetPassword({
    email,
    code,
    password,
  }: ResetPasswordProps): Promise<void> {
    try {
      dispatch(updateLoadingState(true));
      await Auth.forgotPasswordSubmit(email, code, password);
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateLoadingState(false));
    }
  }

  const signOut = async () => {
    try {
      dispatch(updateLoadingState(true));
      await Auth.signOut();
      dispatch(switchMode(AuthMode.SIGN_OUT));
      dispatch(updateUser(null));
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  const completeNewPassword = async ({
    incompletePasswordUser,
    password,
  }: CompleteNewPasswordProps) => {
    try {
      dispatch(updateLoadingState(true));
      await Auth.completeNewPassword(incompletePasswordUser, password);
      dispatch(switchMode(AuthMode.LOGGED_IN));
      await updateSessionInfo();
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateLoadingState(false));
    }
  };
  const signUp = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      dispatch(updateLoadingState(true));
      const { userConfirmed, userSub } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      });
      if (userConfirmed) {
        dispatch(switchMode(AuthMode.SIGN_IN, email));
      } else {
        dispatch(switchMode(AuthMode.CONFIRM_ACCOUNT, email));
      }
      return userSub;
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  const confirmAccount = async ({
    email,
    code,
  }: {
    email: string;
    code: string;
  }) => {
    try {
      dispatch(updateLoadingState(true));
      await Auth.confirmSignUp(email, code);
      dispatch(switchMode(AuthMode.SIGN_IN));
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  const resendSignUp = async ({ email }: { email: string }) => {
    try {
      dispatch(updateResendConfirmationLoadingState(true));
      await Auth.resendSignUp(email);
      dispatch(switchMode(AuthMode.CONFIRM_ACCOUNT, email));
    } catch (e) {
      throw e;
    } finally {
      dispatch(updateResendConfirmationLoadingState(false));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        session: state.session,
        user: state.user,
        userName: state.userName,
        authMode: state?.authMode,
        email: state?.email,
        isLoading: state?.isLoading,
        isInitialLoad: state?.isInitialLoad,
        isResendConfirmationLoading: state?.isResendConfirmationLoading,
        signOut,
        signIn,
        completeNewPassword,
        signUp,
        confirmAccount,
        updateSessionInfo,
        sendResetPasswordCode,
        resetPassword,
        resendSignUp,
      }}
    >
      {state.isInitialLoad === false && children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(`Auth Context not found! Add <AuthProvider> as parent`);
  }
  return authContext;
};

export { AuthContext, AuthProvider, useAuth };
