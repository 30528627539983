import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export function EmptyBubble({ testID, color = '#F9F7F1' }: SvgProps) {
  return (
    <Svg width={10} height={12} fill="none" testID={testID}>
      <Path
        d="M4.998 0 0 2.874v5.752l4.998 2.876L10 8.626V2.874L4.998 0Zm3.747 7.908-3.747 2.156L1.25 7.908V3.594l3.748-2.156 3.747 2.156v4.314Z"
        fill={color}
      />
    </Svg>
  );
}

export function FilledBubble({ testID, color = '#F9F7F1' }: SvgProps) {
  return (
    <Svg width={10} height={12} fill="none" testID={testID}>
      <Path
        d="M0 2.874 4.998 0 10 2.874v5.752l-5.002 2.876L0 8.626V2.874Z"
        fill={color}
      />
    </Svg>
  );
}
