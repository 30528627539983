import React from 'react';
import {
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { ComponentTheme } from '../../themes/defaultTheme';
import { useTheme } from '../../themes';
export interface CardProps extends ViewProps {
  cardContainerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    cardContainer: {
      flex: 1,
      borderRadius: theme.radii.radiusLarge,
      backgroundColor: theme.background.surface,
      paddingVertical: 20,
      paddingHorizontal: 20,
      justifyContent: 'center',
    },
  });

export function Card({ children, cardContainerStyle, ...rest }: CardProps) {
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <View style={[styles.cardContainer, cardContainerStyle]} {...rest}>
      {children ? children : null}
    </View>
  );
}
