import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const PasswordIcon = ({
  width = 16,
  height = 20,
  color = '#636567',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8H5V5c0-1.654 1.346-3 3-3s3 1.346 3 3v3Zm-3 8a2 2 0 1 1 .001-4.001A2 2 0 0 1 8 16Zm6-8h-1V5c0-2.757-2.243-5-5-5S3 2.243 3 5v3H2c-1.103 0-2 .896-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.104-.897-2-2-2Z"
      fill={color}
    />
  </Svg>
);
