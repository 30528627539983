import React from 'react';
import {
  TextProps,
  Text,
  StyleSheet,
  TextStyle,
  AccessibilityProps,
} from 'react-native';
import { TextStyles } from '../../themes/textStyles';

export type StyledTextProps = Omit<TextProps, 'children'> &
  Omit<AccessibilityProps, 'role' | 'accessibilityRole'> & {
    textStyle?: TextStyle;
    /**
     *  The text to be styled.
     */
    children: React.ReactNode;
  };

const createStyles = ({ textStyle }: { textStyle: TextStyle }) =>
  StyleSheet.create({
    textStyle: {
      ...textStyle,
    },
  });

export function StyledText({
  testID,
  children,
  style,
  textStyle = TextStyles.bodyMedium,
  role,
  ...rest
}: StyledTextProps) {
  const styles = createStyles({ textStyle });

  return (
    <Text
      style={[styles.textStyle, style !== undefined && style]}
      testID={testID}
      role={role}
      {...rest}
    >
      {children}
    </Text>
  );
}
