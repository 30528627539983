import defaultTheme, { ComponentTheme, darkTheme } from './defaultTheme';
import { createTheming } from '@callstack/react-theme-provider';
import { Appearance } from 'react-native';

const colorScheme = Appearance.getColorScheme();
let theme = defaultTheme as ComponentTheme;
if (colorScheme === 'dark') {
  theme = darkTheme as ComponentTheme;
}

const { ThemeProvider, withTheme, useTheme } =
  createTheming<ComponentTheme>(theme);

export { ThemeProvider, withTheme, useTheme, defaultTheme };
