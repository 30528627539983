import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const SoundIcon = ({ color = '#fff', ...props }: SvgProps) => (
  <Svg
    width={24}
    height={20}
    fill="none"
    accessibilityLabel="Sound Icon"
    accessibilityHint="Describes Sound Action"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.183 2.099A1 1 0 0 1 11.75 3v14a1 1 0 0 1-1.625.78L5.4 14H1.75a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1H5.4l4.725-3.78a1 1 0 0 1 1.058-.121ZM9.75 5.08l-3.375 2.7A1 1 0 0 1 5.75 8h-3v4h3a1 1 0 0 1 .625.22l3.375 2.7V5.08Zm4.293 1.626L14.75 6l.708-.707.001.002.003.003.008.008.024.025.079.084a9.555 9.555 0 0 1 1.03 1.374c.549.893 1.147 2.203 1.147 3.711s-.599 2.818-1.148 3.712a9.56 9.56 0 0 1-1.03 1.373c-.032.036-.058.064-.078.084l-.024.025-.008.008-.002.003-.002.001L14.75 15l-.707-.707-.001.001.01-.01.047-.051a7.561 7.561 0 0 0 .799-1.07c.45-.731.852-1.671.852-2.663 0-.992-.401-1.932-.851-2.663a7.556 7.556 0 0 0-.8-1.07 2.977 2.977 0 0 0-.048-.051l-.01-.01h.002Zm-.001-.001c-.001-.001 0 0 0 0Zm3.13-4.89c.001 0 .002.001.578-.816l.577-.817.003.002.005.004.016.011a4.352 4.352 0 0 1 .216.166c.14.112.334.274.564.486a13.347 13.347 0 0 1 1.673 1.87C22.018 4.363 23.25 6.816 23.25 10s-1.232 5.636-2.446 7.279a13.349 13.349 0 0 1-1.673 1.87 10.54 10.54 0 0 1-.78.65l-.016.012-.005.003-.002.002c-.001 0-.002.001-.578-.816l-.577-.817.002-.001.026-.02a8.57 8.57 0 0 0 .574-.485c.385-.354.903-.887 1.42-1.587 1.037-1.402 2.055-3.448 2.055-6.09s-1.018-4.688-2.054-6.09a11.351 11.351 0 0 0-1.42-1.587 8.565 8.565 0 0 0-.575-.485l-.026-.02-.002-.001Z"
      fill={color}
    />
  </Svg>
);
