import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const CanopyIcon = ({
  width = 59,
  height = 48,
  color = '#F50',
  testID = 'canopyLogo',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" testID={testID} {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m32 10.667 26.667 15.466V15.467L32 0 0 18.667v10.666L32 48V37.333L9.333 24 32 10.667ZM32 30l-10.667-6L32 18l10.667 6L32 30Z"
      fill={color}
    />
  </Svg>
);
