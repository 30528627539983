import React from 'react';
import Svg, { G, Polyline } from 'react-native-svg';
import { SvgProps } from '../SvgProps';

export function CheckmarkIcon({
  testID,
  width = 14,
  height = 14,
  color = '#5AB500',
}: SvgProps): JSX.Element {
  return (
    <Svg
      testID={testID}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      title="Path 7"
    >
      <G
        id="Sentinel-App"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <G
          id="Onboarding_Password-Suggestion"
          transform="translate(-47.000000, -465.000000)"
          stroke={color}
          strokeWidth="3"
        >
          <Polyline id="Path-7" points="49 473.779661 52.559322 477 59 467" />
        </G>
      </G>
    </Svg>
  );
}
export default CheckmarkIcon;
