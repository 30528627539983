import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export const UpdateIcon = ({
  width = 24,
  height = 24,
  color = '#656565',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" viewBox="0 0 24 24" {...rest}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M0 14a6 6 0 0 0 6 6h13a5 5 0 0 0 .763-9.942 8.003 8.003 0 0 0-14.738-1.98A6.002 6.002 0 0 0 0 14Zm11 .5V7h2v7.5l2.25-2.25 1.25 1.25L12 18l-4.5-4.5 1.25-1.25L11 14.5Z"
      clipRule="evenodd"
    />
  </Svg>
);
