import React, { createContext, useContext, useState } from 'react';
import { ModalContextProps, ModalProps } from './types';
import CanopyModal from './Modal';
const defaultValue = {
  onAction: () => {},
  onDismiss: () => {},
  headerText: 'Failed to retrieve events',
  bodyTextOne: 'Please check your phones network connection and try again',
  bodyTextTwo: '',
  actionText: 'Try Again',
  isVisible: false,
};
const defaultModalContextData = {
  modalData: defaultValue,
  setModalData: () => {},
};
const ModalContext = createContext<ModalContextProps>(defaultModalContextData);

function ModalProvider(props: { children: React.ReactChild }) {
  const [modalData, setModalData] = useState<ModalProps>(defaultValue);

  const modalValue = { modalData, setModalData };
  return (
    <ModalContext.Provider value={modalValue}>
      {props.children}
      <CanopyModal modalData={modalData} setModalData={setModalData} />
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('Modal Provider not found');
  }
  return context;
}

export { ModalProvider, useModal };
