import React from 'react';
import Svg, { G, Polygon, SvgProps } from 'react-native-svg';

export function DisabledIcon({
  testID,
  color = '#8E8E93',
}: SvgProps): JSX.Element {
  return (
    <Svg
      testID={testID}
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      title="Glyphs / Bar Button / Stop / Disabled"
    >
      <G
        id="Sentinel-App"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G
          id="Onboarding_Password-Suggestion"
          transform="translate(-48.000000, -491.000000)"
          fill={color}
        >
          <G
            id="Glyphs-/-Bar-Button-/-Stop-/-Disabled-Copy-2"
            transform="translate(44.000000, 487.000000)"
          >
            <Polygon
              id="CloseRemove"
              points="14.5905267 4.0005267 16.0047403 5.41474026 11.4176335 10.0026335 16.0047403 14.5905267 14.5905267 16.0047403 10.0026335 11.4176335 5.41474026 16.0047403 4.0005267 14.5905267 8.58763348 10.0026335 4.0005267 5.41474026 5.41474026 4.0005267 10.0026335 8.58763348"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}
