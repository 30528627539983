import { StyleSheet } from 'react-native';
import { ComponentTheme } from '../../themes/defaultTheme';

export const getLoaderStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    loadingContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.background.main,
    },
  });
