import React from 'react';
import { useTheme } from '../../themes';
import { View, StyleSheet, Pressable } from 'react-native';
import { ComponentTheme } from '../../themes/defaultTheme';

const createStyles = (
  theme: ComponentTheme,
  isOn?: boolean,
  disabled?: boolean,
) =>
  StyleSheet.create({
    containerSwitch: {
      backgroundColor: disabled
        ? theme.disabled.secondary
        : isOn
        ? theme.primary.main
        : theme.secondary.darkest,
      borderRadius: 40,
      height: 24,
      width: 36,
      justifyContent: 'center',
    },
    circle: {
      borderRadius: 40,
      height: 18,
      width: 18,
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 3,
      marginVertical: 3,
      transform: [{ translateX: isOn && !disabled ? 12 : 0 }],
    },
  });

export type ToggleProps = {
  onToggle?: () => void;
  disabled?: boolean;
  isOn?: boolean;
};

export function Toggle({ onToggle, disabled, isOn }: ToggleProps) {
  const theme = useTheme();
  const styles = createStyles(theme, isOn, disabled);
  const toggleSwitch = () => {
    if (onToggle) {
      onToggle();
    }
  };
  return (
    <>
      <Pressable
        accessibilityRole="button"
        onPress={toggleSwitch}
        style={styles.containerSwitch}
        disabled={disabled}
        testID={isOn ? 'toggle-on' : 'toggle-off'}
      >
        <View style={styles.circle}></View>
      </Pressable>
    </>
  );
}
