import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const CloseIcon = ({
  width = 24,
  height = 24,
  color = '#434547',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" viewBox="0 0 24 24" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4 6 6 6-6 6 2 2 6-6 6 6 2-2-6-6 6-6-2-2-6 6-6-6-2 2Z"
      fill={color}
    />
  </Svg>
);
