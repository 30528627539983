import * as React from 'react';
import Svg, { SvgProps, Path, ClipPath, Defs, G } from 'react-native-svg';

export const LengthIcon = ({
  testID = 'length-icon',
  width = 24,
  height = 24,
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" testID={testID} {...rest}>
    <G clipPath="url(#a)">
      <Path
        fill="#656565"
        fillRule="evenodd"
        d="M15 0H9v24h6V0Zm-2 1h-3v1h3V1Zm-3 7h3v1h-3V8Zm3 7h-3v1h3v-1Zm0 7h-3v1h3v-1Zm-3-11h1v2h-1v-2Zm1 7h-1v2h1v-2ZM10 4h1v2h-1V4Z"
        clipRule="evenodd"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
