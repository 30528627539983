import React from 'react';
import { ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Image, ImageProps } from 'expo-image';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';
import { StyledText } from '../Typography/StyledText';

export interface StepProps extends ImageProps {
  /**
   * The image link. (Please see [Expo Image](https://docs.expo.dev/versions/latest/sdk/image/#props) for info on additional props that can be passed in.)
   */
  source: ImageSourcePropType;
  /**
   * A JSX.Element, such as an Accordion or a StyledText.
   */
  children: JSX.Element;
  /**
   * This number is displayed inside the orange box at the top-left corner. (To indicate what step the user is currently on.)
   */
  index: number;
}

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    indexContainer: {
      position: 'absolute',
      top: 40,
      left: 0,
      height: 20,
      width: 40,
      backgroundColor: theme.primary.main,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 10,
    },
    index: {
      color: theme.text.inverse,
    },
    image: {
      height: 232,
      aspectRatio: 4 / 3,
      borderRadius: theme.radii.radiusLarge,
    },
    childContainer: {
      paddingTop: 16,
    },
    content: {
      paddingHorizontal: 20,
      paddingVertical: 20,
    },
  });

export function Step({ source, children, index, ...rest }: StepProps) {
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <View>
      <View style={styles.content}>
        <Image
          accessibilityIgnoresInvertColors
          source={source}
          style={styles.image}
          {...rest}
        />
        <View style={styles.childContainer}>{children}</View>
      </View>
      <View style={styles.indexContainer}>
        <StyledText
          textStyle={theme.textStyles.bodySemiBold}
          style={styles.index}
        >
          {index}
        </StyledText>
      </View>
    </View>
  );
}
