import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const PlayButton = ({
  testID="play-button",
  width = 32,
  height = 24,
  color = '#FF5500',
}: SvgProps) => (
  <Svg testID={testID} width={width} height={height} fill="none" viewBox={"0 0 32 24"}>
    <Path fill={color} d="M4 0h24s4 0 4 12-4 12-4 12H4s-4 0-4-12S4 0 4 0Z" />
    <Path fill="#fff" d="M12 6v12l10-6-10-6Z" />
  </Svg>
);
