import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ActivityIcon = ({ color = '#fff', ...props }: SvgProps) => (
  <Svg
    width={20}
    height={22}
    fill="none"
    accessibilityLabel="Activity Icon"
    accessibilityHint="Describes Activity Action"
    {...props}
  >
    <Path d="M4 11h2v2H4v-2Z" fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2V0H4v2H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-2V0h-2v2H6Zm8 2H6v1H4V4H2v3h16V4h-2v1h-2V4ZM2 20V9h16v11H2Z"
      fill={color}
    />
  </Svg>
);
