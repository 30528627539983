import * as React from 'react';
import Svg, { SvgProps, Circle, G, Rect, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
export const GpsIcon = ({
  width = 80,
  height = 80,
  color = '#668822',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" viewBox="0 0 80 80" {...rest}>
    <Circle cx={40} cy={40} r={40} fill={color} opacity={0.4} />
    <Circle cx={40} cy={40} r={32} fill={color} opacity={0.4} />
    <Circle cx={40} cy={40} r={24} fill={color} opacity={0.8} />
    <G filter="url(#a)">
      <Rect width={38} height={20} x={21} y={30} fill="#fff" rx={10} />
    </G>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M49 35H30s-4 0-4 5 5 5 5 5h18s5 0 5-5-5-5-5-5Zm-6 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-1 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm9 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-20 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      clipRule="evenodd"
    />
    <Defs></Defs>
  </Svg>
);
