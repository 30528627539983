import * as React from 'react';
import Svg, { SvgProps, Rect, Circle, Path } from 'react-native-svg';

export const LiveIndicator = (props: SvgProps) => (
  <Svg width={72} height={24} fill="none" {...props}>
    <Rect width={72} height={24} rx={12} fill="#434547" />
    <Circle cx={18} cy={12} r={5} fill="#F50" />
    <Path
      d="M30.529 17V6.818h1.233v9.088h4.733V17h-5.966Zm9.108-10.182V17h-1.233V6.818h1.233Zm2.89 0 3.022 8.571h.12l3.022-8.57h1.293L46.245 17h-1.272l-3.74-10.182h1.294ZM51.57 17V6.818h6.145v1.094h-4.912v3.44h4.594v1.094h-4.594v3.46h4.991V17H51.57Z"
      fill="#fff"
    />
  </Svg>
);
