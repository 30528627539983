import * as React from 'react';
import Svg, { SvgProps, Path, ClipPath, Defs, G } from 'react-native-svg';

export const WidthIcon = ({
  testID = 'width-icon',
  width = 24,
  height = 24,
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" testID={testID} {...rest}>
    <G clipPath="url(#a)">
      <Path
        fill="#656565"
        fillRule="evenodd"
        d="M24 15V9H0v6h24Zm-1-2v-3h-1v3h1Zm-7-3v3h-1v-3h1Zm-7 3v-3H8v3h1Zm-7 0v-3H1v3h1Zm11-3v1h-2v-1h2Zm-7 1v-1H4v1h2Zm14-1v1h-2v-1h2Z"
        clipRule="evenodd"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
