import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { ComponentTheme } from '../../../themes/defaultTheme';
import { useTheme } from '../../../themes';
import { defaultHitSlop, StyledText } from '../../../components';
import { ToastContainerProps } from '../types';
import { Warning, CloseIcon } from '../../../assets';
import { useToast } from '../index';

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    container: {
      position: 'absolute',
      left: 0,
      width: '100%',
    },
    // @ts-ignore
    toastCard: {
      backgroundColor: theme.colors.lightOrange,
      ...theme.shadows.small,
      opacity: 1,
      borderRadius: theme.radii.radiusMedium,
      padding: 8,
      margin: 6,
      justifyContent: 'center',
      flex: 1,
    },
    toastText: {
      color: theme.text.inverse,
    },
    bottomTextColor: {
      color: theme.colors.primaryOrange,
    },
    topTextColor: {
      color: theme.colors.darkGrey,
    },
    toastBodyContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconContainer: {
      alignItems: 'flex-start',
    },
    closeButton: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
    },
    textBox: {
      paddingLeft: 10,
      flex: 1,
      width: 0,
    },
  });
export function ToastContainer({ toastData, ...rest }: ToastContainerProps) {
  const theme = useTheme();
  const { hideToast } = useToast();
  const styles = createStyles(theme);
  const handleOnPress = (id: string) => () => {
    hideToast(id);
  };
  return (
    <View style={styles.container}>
      {toastData.map(
        ({
          id,
          onPress,
          title,
          body,
          isToastShown,
          isToastCloseable,
          icon,
          customToastContent,
        }) => (
          <React.Fragment key={title}>
            {isToastShown ? (
              <Pressable {...rest} style={styles.toastCard} onPress={onPress}>
                <View style={styles.toastBodyContainer}>
                  <View style={styles.iconContainer}>
                    {icon ? (
                      icon
                    ) : (
                      <Warning color={theme.colors.primaryOrange} />
                    )}
                  </View>
                  <View style={styles.textBox}>
                    {customToastContent ? (
                      customToastContent
                    ) : (
                      <>
                        <StyledText
                          style={[styles.toastText, styles.topTextColor]}
                          textStyle={theme.textStyles.header1Medium}
                          testID="toast-title"
                        >
                          {title}
                        </StyledText>

                        {body ? (
                          <StyledText
                            style={[styles.toastText, styles.bottomTextColor]}
                            textStyle={theme.textStyles.header1Bold}
                            testID="toast-body"
                          >
                            {body}
                          </StyledText>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </View>
                  {isToastCloseable ? (
                    <Pressable
                      style={styles.closeButton}
                      testID={`close-toast-${id}`}
                      accessibilityRole="button"
                      onPress={handleOnPress(id)}
                      hitSlop={defaultHitSlop}
                    >
                      <CloseIcon width={15} height={15} />
                    </Pressable>
                  ) : (
                    <></>
                  )}
                </View>
              </Pressable>
            ) : (
              <></>
            )}
          </React.Fragment>
        ),
      )}
    </View>
  );
}
