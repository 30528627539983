export type ShadowValuesType = {
    small: ShadowType,
    medium: ShadowType,
    large: ShadowType
};

interface ShadowType {
    shadowColor: string,
    shadowOffset: {
        width: number,
        height: number,
    },
    shadowOpacity: number,
    shadowRadius: number,
    elevation: number,
}

const ShadowValues: ShadowValuesType = {
    small: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 6,
    },
    medium: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.08,
        shadowRadius: 24,
        elevation: 9,
    },
    large: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 16,
        },
        shadowOpacity: 0.08,
        shadowRadius: 64,
        elevation: 12,
    }
}

export default ShadowValues;
