import React from 'react';
import {
  FlatList,
  StyleSheet,
  View,
  FlatListProps,
  ImageSourcePropType,
} from 'react-native';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';
import { Step } from '../Step';
import { Accordion, AccordionProps } from '../Accordion';

type StepType = {
  imageSource: ImageSourcePropType;
  accordionProps: AccordionProps;
};

export interface StepsContainerProps
  extends Omit<FlatListProps<StepType>, 'renderItem'> {}

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    stepContainer: {
      backgroundColor: theme.background.surface,
    },
  });

export function StepsContainer({ data, ...rest }: StepsContainerProps) {
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <FlatList
      style={[styles.stepContainer]}
      {...rest}
      data={data}
      renderItem={({ item, index }) => (
        <View key={index}>
          <Step source={item.imageSource} index={index + 1}>
            <Accordion {...item.accordionProps} />
          </Step>
        </View>
      )}
    />
  );
}
