import { Platform, StyleSheet } from 'react-native';
import { ComponentTheme } from '../../themes/defaultTheme';
import pixelRatio from '../../utils/pixelRatio';

export const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    formView: {
      marginVertical: 8,
      zIndex: Platform.OS === 'web' ? undefined : 0,
    },
    formBorder: {
      borderRadius: theme.radii.radiusLarge,
    },
    buttonContainer: {
      marginTop: pixelRatio.heightPercentageToDP('2'),
    },
    conditionViewContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: pixelRatio.heightPercentageToDP('3'),
    },
    conditionIcon: {
      flexGrow: 0,
      flexBasis: 16,
      alignItems: 'flex-end',
    },
    conditionText: {
      flex: 0.6,
      flexGrow: 1,
      marginLeft: 4,
    },
    submitButton: {
      minHeight: 56,
    },
  });
