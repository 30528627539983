import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export function Battery({
  width = 24,
  height = 24,
  testID = 'batteryIcon',
  color = '#636567',
  ...props
}: SvgProps) {
  return (
    <Svg width={width} height={height} fill="none" testID={testID} {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5S0 5 0 8v8c0 3 3 3 3 3h15s3 0 3-3V8c0-3-3-3-3-3H3Zm0 1S1 6 1 8v8c0 2 2 2 2 2h15s2 0 2-2V8c0-2-2-2-2-2H3ZM2 8c0-1 1-1 1-1h8s1 0 1 1v8c0 1-1 1-1 1H3s-1 0-1-1V8Zm22 4c0 1.746-1.182 3.322-3 4V8c1.818.678 3 2.254 3 4Z"
        fill={color}
      />
    </Svg>
  );
}
