import { Condition } from '../components';

function mandatory(input: string, fieldName: string = 'This field'): string {
  if (input) {
    return '';
  } else return `${fieldName} is required`;
}

function email(input: string, fieldName?: string): string {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (input) {
    if (regex.test(input)) {
      return '';
    } else return 'Please enter a valid email address';
  }
  return mandatory(input, fieldName);
}

function confirmationCode(input: string): string {
  const regex = /[0-9]{6,6}/;
  if (input) {
    if (regex.test(input)) {
      return '';
    } else return 'This code is invalid';
  }
  return mandatory(input);
}

function taggingInput(input: string): string {
  const regex = /^\s+$/;
  if (input) {
    if (regex.test(input)) {
      return 'We could not submit your feedback, please try again.';
    } else return '';
  }
  return mandatory(input);
}

function dropDownValidation(selectedValue: string): string {
  if (selectedValue === '') {
    return 'This field is required';
  }
  return '';
}
function livePassword(input: string): Condition[] {
  const conditions: Condition[] = [
    { regex: /^.{8,256}$/, name: 'Be at least 8 characters', isMet: false },
    { regex: /(?=.*[0-9])/, name: 'Contain at least one number', isMet: false },
    {
      regex: /(?=.*[#^@$!%*?&-+._~=-])/,
      name: 'Contain at least one special character',
      isMet: false,
    },
    {
      regex: /(?=.*?[a-z])(?=.*?[A-Z])/,
      name: 'Contain upper and lowercase letters',
      isMet: false,
    },
  ];
  conditions.forEach((condition: Condition) => {
    condition.isMet = condition.regex.test(input);
  });
  return conditions;
}

function phoneNumber(input: string): string {
  const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  if (input) {
    if (regex.test(input)) {
      return '';
    } else return 'This phone number is invalid';
  }
  return mandatory(input);
}

function privacyPolicyChecked(input: boolean): string {
  if (input) {
    return '';
  } else
    return 'Please accept the Terms & Conditions to continue registering your account.';
}

export default {
  email,
  mandatory,
  livePassword,
  confirmationCode,
  taggingInput,
  dropDownValidation,
  phoneNumber,
  privacyPolicyChecked,
};
