import { StyledText } from '../Typography/StyledText';
import React from 'react';
import { useTheme } from '../../themes';
export interface FormErrorProps {
  isInErrorState?: boolean;
  errorMessage?: string;
}
export function FormError({
  isInErrorState = false,
  errorMessage = '',
}: FormErrorProps) {
  const theme = useTheme();
  return (
    <>
      {isInErrorState ? (
        <StyledText
          textStyle={theme.textStyles.errorText}
          testID="input-validation-error-message"
        >
          {errorMessage}
        </StyledText>
      ) : null}
    </>
  );
}
