import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

export function ShowIcon({ testID, width = 24, height = 12, color = '#000000' }: SvgProps): JSX.Element {
  return (
    <Svg
      testID={testID}
      width={width}
      height={height}
      viewBox="0 0 24 12"
      title="GeneralSystems/Show/16 Copy 3"
    >
      <G
        id="Sentinel-App"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G
          id="Onboarding_Login-Fill-Copy"
          transform="translate(-305.000000, -369.000000)"
          fill={color}
        >
          <G id="Show" transform="translate(305.000000, 363.000000)">
            <Path d="M14.2854584,11.9992081 C14.2854584,13.3011519 13.2628751,14.3560115 12.0007677,14.3560115 C10.7386604,14.3560115 9.71607703,13.3011519 9.71607703,11.9992081 C9.71607703,10.6972642 10.7386604,9.64240461 12.0007677,9.64240461 C13.2628751,9.64240461 14.2854584,10.6972642 14.2854584,11.9992081 L14.2854584,11.9992081 Z M12.0007677,15.9272138 C9.90186169,15.9272138 8.19294991,14.1659467 8.19294991,11.9992081 C8.19294991,9.8324694 9.90186169,8.0712023 12.0007677,8.0712023 C14.1012091,8.0712023 15.8085855,9.8324694 15.8085855,11.9992081 C15.8085855,14.1659467 14.1012091,15.9272138 12.0007677,15.9272138 L12.0007677,15.9272138 Z M24,11.8661627 C22.0761308,10.1207343 17.4315143,6.5 12.0007677,6.5 C6.57002111,6.5 1.92233382,10.1223182 0,11.8677466 L1.31738213,12.7848092 C2.59944981,11.6681785 4.85036146,9.95284377 7.57725034,8.9312455 C7.00454226,9.80712743 6.66982279,10.8635709 6.66982279,11.9992081 C6.66982279,15.037077 9.05738596,17.5 12.0007677,17.5 C14.9456849,17.5 17.3317126,15.037077 17.3317126,11.9992081 C17.3317126,10.8635709 16.9985286,9.80712743 16.4258205,8.9312455 C19.1496385,9.9512599 21.4005502,11.6681785 22.6826179,12.7848092 L24,11.8661627 Z" />
          </G>
        </G>
      </G>
    </Svg>
  );
}
