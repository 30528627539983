import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const CanopyIconHeader = ({
  width = 44,
  height = 36,
  color = '#F50',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m24 8 20 11.6v-8L24 0 0 14v8l24 14v-8L7 18 24 8Zm0 14.5L16 18l8-4.5 8 4.5-8 4.5Z"
      fill={color}
    />
  </Svg>
);
