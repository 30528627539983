import React, { useEffect } from 'react';
import {
  findNodeHandle,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { DefaultRadioButton } from './RadioButton';
import { ComponentTheme } from '../../themes/defaultTheme';
import { useTheme } from '../../themes';
const KeyCode = {
  RETURN: 13,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};
interface RadioButtonProps {
  value: any;
  label: string;
  index: number;
  containerStyle?: ViewStyle;
  ButtonComponent?: any;
}
interface RadioGroupContext {
  currentValue: any;
  currentFocusIndex: number;
  setFocusIndex: () => void;
  focusNext: () => void;
  focusPrev: () => void;
  onChange: () => void;
  childCount: number;
  itemDirection: ItemDirections;
  horizonSeparator: boolean;
}
export enum ItemDirections {
  horizontal = 'horizontal',
  vertical = 'vertical',
}
const radioStyles = StyleSheet.create({
  optionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
export const RadioContext = React.createContext<
  RadioGroupContext | Record<any, any>
>({});

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    radioButtonContainer: {
      flex: 1,
      paddingHorizontal: 24,
    },
    horizontalLine: {
      height: 1,
      backgroundColor: theme.background.surfaceSeparator,
    },
  });
export function RadioButton({
  value,
  label,
  index,
  containerStyle,
  ButtonComponent = DefaultRadioButton,
}: RadioButtonProps) {
  const theme = useTheme();
  const styles = createStyles(theme);
  const containerRef = React.useRef<any>(null);
  const {
    currentValue,
    currentFocusIndex,
    setFocusIndex,
    focusNext,
    focusPrev,
    onChange,
    childCount,
    itemDirection,
    horizonSeparator,
  } = React.useContext(RadioContext);
  const isFocused = currentFocusIndex === index;
  useEffect(() => {
    function handleKeydown(e: any) {
      let handled = false;
      switch (e.keyCode) {
        case KeyCode.SPACE:
        case KeyCode.RETURN:
          onChange(value);
          handled = true;
          break;
        case KeyCode.UP:
        case KeyCode.LEFT:
          focusPrev();
          handled = true;
          break;
        case KeyCode.DOWN:
        case KeyCode.RIGHT:
          focusNext();
          handled = true;
          break;
        default:
          break;
      }
      if (handled) {
        e.stopPropagation();
        e.preventDefault();
      }
    }

    const node = containerRef.current;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    node?.addEventListener && node.addEventListener('keydown', handleKeydown);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      node?.removeEventListener &&
        node.removeEventListener('keydown', handleKeydown);
    };
  }, [focusNext, focusPrev, value, onChange]);
  useEffect(() => {
    const rafId = requestAnimationFrame(() => {
      if (isFocused) {
        const node = containerRef.current;
        if (typeof node?.focus === 'function') {
          node?.focus();
        }
      }
    });
    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [isFocused]);
  const isSelected = currentValue === value;

  return (
    <View style={styles.radioButtonContainer}>
      <Pressable
        ref={(ref) => (containerRef.current = findNodeHandle(ref))}
        onPress={() => {
          setFocusIndex(index);
          onChange(value);
        }}
        onFocus={() => setFocusIndex(index)}
        accessibilityRole="radio"
        aria-checked={isSelected}
        accessible={isFocused || isSelected}
        style={[radioStyles.optionContainer, containerStyle]}
      >
        <ButtonComponent label={label} index={index} isSelected={isSelected} />
      </Pressable>
      {itemDirection == ItemDirections.vertical &&
      horizonSeparator &&
      childCount - 1 > index ? (
        <View style={styles.horizontalLine}></View>
      ) : null}
    </View>
  );
}

interface RadioGroupProps<T> {
  children: React.ReactElement[] | React.ReactElement;
  labelID: string;
  value: T;
  onChange: (value: T) => void;
  itemDirection?: ItemDirections;
  horizonSeparator?: boolean;
}
export function RadioGroup({
  children,
  labelID,
  value,
  onChange,
  itemDirection = ItemDirections.horizontal,
  horizonSeparator = false,
  ...props
}: RadioGroupProps<any>) {
  const [focusIndex, setFocusIndex] = React.useState(-1);

  const buttons: React.ReactElement[] = [];
  const newChildren = React.Children.map(children, (child) => {
    if (child.type === RadioButton) {
      buttons.push(child);
      return React.cloneElement(child, { index: buttons.length - 1 });
    }
    return child;
  });

  const childCount = buttons.length;

  function focusNext() {
    const nextFocusIndex = (focusIndex + 1) % childCount;
    setFocusIndex(nextFocusIndex);
    onChange(buttons[nextFocusIndex].props.value);
  }

  function focusPrev() {
    const prevFocusIndex = focusIndex === 0 ? childCount - 1 : focusIndex - 1;
    setFocusIndex(prevFocusIndex);
    onChange(buttons[prevFocusIndex].props.value);
  }

  if (typeof labelID !== 'string') {
    throw new Error(
      `RadioGroup should be given ID to its label in order to be accessible.`,
    );
  }

  return (
    <RadioContext.Provider
      value={{
        currentValue: value,
        currentFocusIndex: focusIndex,
        setFocusIndex,
        focusNext,
        focusPrev,
        onChange,
        childCount,
        itemDirection,
        horizonSeparator,
      }}
    >
      <View
        aria-labelledby={labelID}
        /* @ts-ignore*/
        onBlur={() => setFocusIndex(-1)}
        {...props}
        style={{
          flexDirection:
            itemDirection === ItemDirections.vertical ? 'column' : 'row',
          flex: horizonSeparator ? 1 : 0.25,
        }}
      >
        {newChildren}
      </View>
    </RadioContext.Provider>
  );
}
