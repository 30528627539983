import React from 'react';
import { useTheme } from '../../themes';
import { View, StyleSheet, Pressable } from 'react-native';
import { ComponentTheme } from '../../themes/defaultTheme';
import LivestreamCameraIcon from '../../assets/livestream/LivestreamCameraIcon';

const createStyles = (
  theme: ComponentTheme,
  isOn: boolean | undefined,
  disabled: boolean | undefined,
) =>
  StyleSheet.create({
    containerSwitch: {
      backgroundColor: disabled
        ? theme.disabled.secondary
        : isOn
        ? theme.primary.main
        : theme.secondary.darkest,
      borderRadius: 40,
      height: 40,
      width: 60,
      justifyContent: 'center',
    },
    circle: {
      borderRadius: 40,
      height: 34,
      width: 34,
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 3,
      marginVertical: 3,
      transform: [{ translateX: isOn && !disabled ? 20 : 0 }],
    },
  });

export type LivestreamToggleProps = {
  onToggle?: () => void;
  disabled?: boolean | undefined;
  isOn: boolean | undefined;
};

export function LivestreamToggle({
  onToggle,
  disabled,
  isOn,
}: LivestreamToggleProps) {
  const theme = useTheme();
  const styles = createStyles(theme, isOn, disabled);
  const toggleSwitch = () => {
    if (onToggle) {
      onToggle();
    }
  };
  return (
    <>
      <Pressable
        accessibilityRole="button"
        onPress={toggleSwitch}
        style={styles.containerSwitch}
        disabled={disabled}
        testID={isOn ? 'livestream-toggle-on' : 'livestream-toggle-off'}
      >
        <View style={styles.circle}>
          <LivestreamCameraIcon
            fill={
              disabled
                ? theme.disabled.secondary
                : isOn
                ? theme.primary.main
                : theme.text.secondary
            }
          />
        </View>
      </Pressable>
    </>
  );
}
