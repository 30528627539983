import * as React from 'react';
import Svg, { SvgProps, Circle } from 'react-native-svg';

export const TimelineBubbleIcon = ({
  testID,
  color = '#636567',
  ...props
}: SvgProps) => (
  <Svg testID={testID} width={8} height={8} fill="none" {...props}>
    <Circle cx={4} cy={4} r={4} fill={color} />
  </Svg>
);
