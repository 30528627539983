import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export function Update({
  width = 24,
  height = 24,
  color = '#636567',
  viewBox = '0 0 24 24',
  testID = 'updateIcon',
  ...props
}: SvgProps) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      testID={testID}
      {...props}
    >
      <G clipPath="url(#a)">
        <Path d="M19 9h-4V3H9v6H5l7 7 7-7ZM5 18v2h14v-2H5Z" fill={color} />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
