import {
  CheckmarkIcon,
  Condition,
  DisabledIcon,
  FormError,
  FormErrorProps,
  FormLabel,
  FormLabelProps,
  InputText,
  InputTextProps,
  StyledText,
  useTheme,
} from '../..';
import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useId,
  useState,
} from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from 'react-native';
import Validators from '../../utils/Validators';
import pixelRatio from '../../utils/pixelRatio';

export interface FormTextInputProps
  extends InputTextProps,
    Pick<FormLabelProps, 'label' | 'renderTopRightButton'>,
    FormErrorProps {
  textInputProps: InputTextProps;
  viewContainerStyles?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  isPasswordInput?: boolean;
}

const createStyles = () =>
  StyleSheet.create({
    conditionViewContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: pixelRatio.heightPercentageToDP('3'),
    },
    conditionIcon: {
      flexGrow: 0,
      flexBasis: 16,
      alignItems: 'flex-end',
    },
    conditionText: {
      flex: 0.6,
      flexGrow: 1,
      marginLeft: 4,
    },
  });

const WebLabelWrapper = ({
  forId,
  children,
}: {
  forId: string;
  children?: ReactNode;
}) => {
  if (Platform.OS === 'web') {
    return (
      <label htmlFor={forId} data-testid={`${forId}-label`}>
        {children}
      </label>
    );
  }
  return <>{children}</>;
};

export const FormTextInput = forwardRef(function FormTextInput(
  {
    label,
    textInputProps,
    viewContainerStyles,
    style,
    isInErrorState,
    errorMessage,
    isPasswordInput = false,
    renderTopRightButton,
    ...rest
  }: FormTextInputProps,
  ref: ForwardedRef<TextInput>,
) {
  const theme = useTheme();
  const styles = createStyles();
  const [conditions, setConditions] = useState<Condition[]>([]);
  const checkValidation = (text: string) => {
    setConditions(Validators.livePassword(text));
  };
  // @ts-ignore
  const onLabelPress = () => ref?.current?.focus();
  const uuid = useId();
  return (
    <>
      <View style={[{ marginBottom: 10 }, style]}>
        <WebLabelWrapper forId={uuid}>
          {label ? (
            <FormLabel
              label={label}
              {...(Platform.OS === 'web' ? { accessibilityRole: 'label' } : {})}
              onPress={onLabelPress}
              renderTopRightButton={renderTopRightButton}
            />
          ) : null}

          <InputText
            isInErrorState={isInErrorState}
            ref={ref}
            id={uuid}
            viewContainerStyles={viewContainerStyles}
            onChangeText={checkValidation}
            {...rest}
            {...textInputProps}
          />
          <FormError
            isInErrorState={isInErrorState}
            errorMessage={errorMessage}
          />
        </WebLabelWrapper>
      </View>
      {isPasswordInput
        ? conditions.map((condition: Condition, index: number) => {
            return (
              <View key={index} style={styles.conditionViewContainer}>
                <View style={styles.conditionIcon}>
                  {condition.isMet ? (
                    <CheckmarkIcon
                      testID={`check-${index}`}
                      color={theme.primary.main}
                    />
                  ) : (
                    <DisabledIcon
                      color={theme.colors.trafficLightRed}
                      testID={`cross-${index}`}
                    />
                  )}
                </View>
                <View style={styles.conditionText}>
                  {condition.isMet ? (
                    <StyledText textStyle={theme.textStyles.bodyMedium}>
                      {condition.name}
                    </StyledText>
                  ) : (
                    <StyledText textStyle={theme.textStyles.errorText}>
                      {condition.name}
                    </StyledText>
                  )}
                </View>
              </View>
            );
          })
        : null}
    </>
  );
});
