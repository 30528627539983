import React from 'react';
import Svg, { Rect, SvgProps } from 'react-native-svg';
export function SelectedRadioButton({ testID, color }: SvgProps) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" testID={testID}>
      <Rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={color} />
      <Rect x="3" y="3" width="10" height="10" rx="5" fill={color} />
    </Svg>
  );
}
