import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const VehicleIcon = ({
  width = 24,
  height = 14,
  color = '#636567',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5h5V2H8L7 5Zm7 0h9a1 1 0 0 1 1 1v3a2 2 0 0 1-2 2h-1.184A2.992 2.992 0 0 0 18 9a2.992 2.992 0 0 0-2.816 2H8.816A2.992 2.992 0 0 0 6 9a2.992 2.992 0 0 0-2.816 2H1a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2h3l1.497-3.743A2 2 0 0 1 8.354 0H13a1 1 0 0 1 1 1v4Zm-8 5a2 2 0 1 0 .001 4.002A2 2 0 0 0 6 10Zm12 0a2 2 0 1 0 .001 4.002A2 2 0 0 0 18 10Z"
      fill={color}
    />
  </Svg>
);
