import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const RightArrow = ({
  color = '#FF5500',
  width = 16,
  height = 12,
  testID = 'right-arrow',
}: SvgProps) => (
  <Svg testID={testID} width={width} height={height} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5h12L8.5 1.5 10 0l6 6-6 6-1.5-1.5L12 7H0V5Z"
      fill={color}
    />
  </Svg>
);

export default RightArrow;
