import * as React from 'react';
import Svg, { Rect, G, Path, Defs, ClipPath, SvgProps } from 'react-native-svg';
import { ColorValue } from 'react-native';

export interface RecenterProps extends SvgProps {
  arrowColor: ColorValue;
  backgroundColor: ColorValue;
}
export const Recenter = ({
  arrowColor = '#818589',
  backgroundColor = '#fff',
  ...props
}: RecenterProps) => (
  <Svg width={48} height={52} fill="none" {...props}>
    <Rect width={48} height={52} fill={backgroundColor} rx={8} />
    <Rect width={40} height={40} x={4} y={6} fill={backgroundColor} rx={8} />
    <G clipPath="url(#a)">
      <Path
        fill={arrowColor}
        d="m33 17-18 7.53v.98l6.84 2.65L24.48 35h.98L33 17Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M12 14h24v24H12z" />
      </ClipPath>
    </Defs>
  </Svg>
);
