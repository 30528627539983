import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const ExpandIcon = ({
  width = 24,
  height = 24,
  fill = '#fff',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fill={fill}
      fillRule="evenodd"
      d="M19 6.414 14.414 11 13 9.586 17.586 5H13V3h8v8h-2V6.414ZM5 17.586 9.586 13 11 14.414 6.414 19H11v2H3v-8h2v4.586Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default ExpandIcon;
