import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export const Warning = ({
  width = 24,
  height = 24,
  color = '#E10044',
  ...props
}: SvgProps) => (
  <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C10.6862 2 10 3.5 10 3.5L0.500044 19C0.500044 19 6.43432e-05 20 0.500048 21C1.00003 22 2.00004 22 2.00004 22H22C22 22 23 22 23.5 21C24 20 23.5 19 23.5 19L14 3.5C14 3.5 13.33 2 12 2ZM11 8.5C11 8.22386 11.2239 8 11.5 8H12.5C12.7762 8 13 8.22386 13 8.5V14.5C13 14.7761 12.7762 15 12.5 15H11.5C11.2239 15 11 14.7761 11 14.5V8.5ZM13.5 17.5C13.5 18.3284 12.8285 19 12 19C11.1716 19 10.5 18.3284 10.5 17.5C10.5 16.6716 11.1716 16 12 16C12.8285 16 13.5 16.6716 13.5 17.5Z"
      fill={color}
    />
  </Svg>
);
