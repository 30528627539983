import pixelRatio from '../../utils/pixelRatio';
import { ComponentTheme } from '../../themes/defaultTheme';
import { StyleSheet } from 'react-native';

export const createStyles = (theme: ComponentTheme) => {
  return StyleSheet.create({
    modalContainer: {
      backgroundColor: theme.background.surface,
      margin: pixelRatio.widthPercentageToDP('4'),
      marginTop: pixelRatio.heightPercentageToDP('5.9'),
      borderRadius: theme.radii.radiusXLarge,
      ...theme.shadows.large,
    },
    modalTextContainer: {
      padding: pixelRatio.widthPercentageToDP('3.2'),
      textAlign: 'center',
    },
    modalHeaderText: {
      marginBottom: 8,
      marginTop: 0,
    },
    modalButtonWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 8,
      marginRight: 8,
    },
    modalButtonDivider: {
      width: 1,
      height: '100%',
      backgroundColor: theme.text.secondary,
    },
    modalButton: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      width: '50%',
      paddingTop: 6,
      paddingBottom: 6,
    },
  });
};

export const getPrimaryColorStyles = (
  theme: ComponentTheme,
  primary: boolean,
) => {
  return {
    color: primary ? theme.text.accent : theme.text.main,
  };
};
