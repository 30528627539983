import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export const SetupIcon = ({
  width = 24,
  height = 24,
  fill = '#656565',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" viewBox="0 0 24 24" {...rest}>
    <Path
      fill={fill}
      fillRule="evenodd"
      d="M5 3v18c0 3 3 3 3 3h8s3 0 3-3V3c0-3-3-3-3-3H8S5 0 5 3Zm12 17.5v-17C17 2 15.5 2 15.5 2c-1 0-1 1-1 1s-.219 1-1 1h-3c-1 0-1-1-1-1s0-1-1-1C8.5 2 7 2 7 3.5v17C7 22 8.5 22 8.5 22h7s1.5 0 1.5-1.5Zm-3.5-.5h-3s-.5 0-.5.5.5.5.5.5h3s.5 0 .5-.5-.5-.5-.5-.5Z"
      clipRule="evenodd"
    />
  </Svg>
);
