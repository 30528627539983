import { StyledText } from '../Typography/StyledText';
import React, { useContext } from 'react';
import { useTheme } from '../../themes';
import { View, StyleSheet } from 'react-native';
import { UnselectedRadioButton, SelectedRadioButton } from '../../assets';
import { RadioContext } from './index';

export interface RadioButtonProps {
  isSelected: boolean;
  label: string;
}

const createStyles = (horizonSeparator: boolean) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: horizonSeparator ? 80 : 24,
    },
    label: {
      marginLeft: 16,
      paddingRight: 8,
    },
  });
export function DefaultRadioButton({ isSelected, label }: RadioButtonProps) {
  const { horizonSeparator } = useContext(RadioContext);
  const theme = useTheme();
  const styles = createStyles(horizonSeparator);
  return (
    <View style={styles.container}>
      <View>
        {isSelected ? (
          <SelectedRadioButton
            color={theme.primary.main}
            testID={`selected-button-${label.replace(' ', '_')}`}
          />
        ) : (
          <UnselectedRadioButton
            color={theme.text.secondary}
            testID={`unselected-button-${label.replace(' ', '_')}`}
          />
        )}
      </View>
      <StyledText
        textStyle={
          isSelected
            ? theme.textStyles.header1Bold
            : theme.textStyles.header1Medium
        }
        style={styles.label}
      >
        {label}
      </StyledText>
    </View>
  );
}
