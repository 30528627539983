import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export function Device({
  width = 24,
  height = 24,
  testID = 'deviceIcon',
  color = '#636567',
  ...props
}: SvgProps) {
  return (
    <Svg width={width} height={height} fill="none" testID={testID} {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 5h-14S0 5 0 10.5 5.5 16 5.5 16h13s5.5 0 5.5-5.5S18.5 5 18.5 5Zm0 2h-13S2 7 2 10.5 5.5 14 5.5 14h13s3.5 0 3.5-3.5S18.5 7 18.5 7ZM3 19v-2h18v2H3Zm11.5-8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-1.5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm7.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill={color}
      />
    </Svg>
  );
}
