import { StyledText } from '../Typography/StyledText';
import React from 'react';
import {
  Pressable,
  PressableProps,
  StyleProp,
  StyleSheet,
  TextStyle,
} from 'react-native';
import { SelectedCheckBox, UnselectedCheckBox } from '../../assets';
import { useTheme } from '../../themes';

interface CheckboxProps extends PressableProps {
  /**
   * Displays a checked or unchecked SVG.
   */
  checked: boolean;
  /**
   * Text that will be displayed after the checkbox SVG.
   */
  displayText: string | JSX.Element;
  testID?: string;
  onPress: () => void;
  /**
   * Allows custom styling for the displayText.
   */
  displayTextStyle?: StyleProp<TextStyle>;
}
const createStyles = () =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    label: {
      marginLeft: 10,
    },
  });
export function Checkbox({
  checked = false,
  displayText,
  testID,
  onPress,
  displayTextStyle,
  ...rest
}: CheckboxProps) {
  const theme = useTheme();
  const styles = createStyles();
  return (
    <Pressable
      style={styles.container}
      testID={testID}
      accessibilityRole="checkbox"
      onPress={onPress}
      {...rest}
    >
      <>
        {checked ? (
          <SelectedCheckBox
            color={theme.text.main}
            testID={`selected-checkbox-button-${testID}`}
          />
        ) : (
          <UnselectedCheckBox
            color={theme.text.main}
            testID={`unselected-checkbox-button-${testID}`}
          />
        )}

        {typeof displayText === 'string' ? (
          <StyledText
            style={[
              styles.label,
              displayTextStyle !== undefined && displayTextStyle,
            ]}
            textStyle={theme.textStyles.bodyMedium}
          >
            {displayText}
          </StyledText>
        ) : (
          displayText
        )}
      </>
    </Pressable>
  );
}
