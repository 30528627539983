import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { SvgProps } from '../SvgProps';

export function HideIcon({ testID, width = 24, height = 19, color = '#000000' }: SvgProps): JSX.Element {
  return (
    <Svg
      testID={testID}
      width={width}
      height={height}
      viewBox="0 0 24 19"
      title="GeneralSystems/Show/16 Copy 2"
    >
      <G
        id="Sentinel-App"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G
          id="Onboarding_Login-Fill-Copy"
          transform="translate(-330.000000, -365.000000)"
          fill={color}
        >
          <G id="Hide" transform="translate(330.000000, 363.000000)">
            <Path d="M8.19305474,11.949874 C8.19305474,9.88167311 9.90045295,8.20050395 11.9993858,8.20050395 C12.5751721,8.20050395 13.1125726,8.33808164 13.5993039,8.56334621 L8.56155795,13.5252142 C8.33277887,13.0444482 8.19305474,12.5153032 8.19305474,11.949874 L8.19305474,11.949874 Z M20.061929,2.2 L21.1413363,3.25980178 L3.93837807,20.2 L2.85897075,19.1386864 L7.42687514,14.642466 C6.94628554,13.8547959 6.66990813,12.9340837 6.66990813,11.949874 C6.66990813,10.8643709 7.00463188,9.85748362 7.57734729,9.01992273 C4.85042352,9.99506131 2.59794764,11.6338989 1.31586355,12.699748 L1.31586355,12.699748 L0,11.8243911 C1.92235842,10.1583403 6.56856975,6.69924408 11.9993858,6.69924408 C13.0772577,6.69924408 14.1182793,6.83833361 15.1178443,7.06964556 L15.1178443,7.06964556 L20.061929,2.2 Z M16.7959158,9.65429195 C17.1383167,10.3482278 17.3317808,11.1268268 17.3317808,11.9492693 C17.3317808,14.8489837 14.9457225,17.1998992 12.0007677,17.1998992 C11.1654938,17.1998992 10.3778182,17.0048715 9.67305576,16.6677306 L9.67305576,16.6677306 L10.8399826,15.5202419 C11.2054149,15.6351419 11.5954142,15.7001512 12.0007677,15.7001512 C14.101236,15.7001512 15.8086342,14.0174702 15.8086342,11.9492693 C15.8086342,11.5516546 15.7441462,11.1676466 15.6259181,10.8063161 L15.6259181,10.8063161 Z M18.315838,8.15847472 C20.8677227,9.30445154 22.8898841,10.8616496 24,11.8216697 L24,11.8216697 L22.682601,12.7000504 C21.5156742,11.7309592 19.5457174,10.2931967 17.1489111,9.30596338 L17.1489111,9.30596338 Z" />
          </G>
        </G>
      </G>
    </Svg>
  );
}
