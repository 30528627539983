import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const Logo = ({ width = 64, height = 64, ...rest }: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m32 18.667 26.667 15.466V23.467L32 8 0 26.667v10.666L32 56V45.333L9.333 32 32 18.667ZM32 38l-10.667-6L32 26l10.667 6L32 38Z"
      fill="#F50"
    />
  </Svg>
);
