import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const TimelineIcon = ({ color = '#636567' }: SvgProps) => (
  <Svg width={24} height={24} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM21 5H9v2h12V5ZM9 11h12v2H9v-2Zm12 6H9v2h12v-2Z"
      fill={color}
    />
  </Svg>
);
