import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

interface StarProps extends SvgProps {
  filled: Boolean;
}

export const Star = ({
  testID = 'star',
  width = 24,
  height = 24,
  filled = true,
  ...rest
}: StarProps) => (
  <Svg
    testID={testID}
    width={width}
    height={height}
    fill="none"
    {...rest}
    viewBox={`0 0 24 24`}
    {...rest}
  >
    {filled ? (
      <Path
        fill={'#FF5500'}
        d="M21.108 10.014a.5.5 0 0 0-.285-.875L14.81 8.62l-2.35-5.535a.5.5 0 0 0-.92 0L9.19 8.63l-6.01.51a.5.5 0 0 0-.284.876L7.46 13.97l-1.37 5.876a.5.5 0 0 0 .745.542L12 17.27l5.167 3.119a.5.5 0 0 0 .746-.541L16.55 13.97l4.558-3.956Z"
      />
    ) : (
      <Path
        fill={'#656565'}
        d="M21.108 10.014a.5.5 0 0 0-.285-.875L14.81 8.62l-2.35-5.535a.5.5 0 0 0-.92 0L9.19 8.63l-6.01.51a.5.5 0 0 0-.284.876L7.46 13.97l-1.37 5.876a.5.5 0 0 0 .745.542L12 17.27l5.167 3.119a.5.5 0 0 0 .746-.541L16.55 13.97l4.558-3.956ZM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4Z"
      />
    )}
  </Svg>
);
