type ColorKeys = keyof typeof Colors;
export type ColorsType = {
  [key in ColorKeys]: string;
};

export enum Colors {
  primaryOrange = '#FF5500',
  primaryOrangeLighter = '#FF7733',
  primaryOrangeLightest = '#FF9966',
  primaryOrangeDarker = '#CC4400',
  primaryOrangeDarkest = '#993300',
  lightOrange = '#FFDDCC',
  black = '#000000',
  white = '#FFFFFF',
  lightGreyRegularB = '#B5B5B5',
  lightGreyRegularD = '#D5D5D5',
  lightGreyRegularE = '#E5E5E5',
  lightGreyRegularF = '#F5F5F5',
  darkGreyRegular2 = '#252525',
  darkGreyRegular3 = '#353535',
  darkGreyRegular4 = '#454545',
  darkGreyRegular6 = '#656565',
  darkGreyRegular8 = '#858585',
  darkGreyDisabled = '#959595',
  trafficLightRed = '#E10044',
  trafficLightAmber = '#FF7733',
  trafficLightGreen = '#668822',
  darkGrey = '#414549',
  darkModeTrafficLightRed = '#FF155B',
  darkModeTrafficLightGreen = '#7DA62B',
}
