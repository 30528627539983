import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const EmailIcon = ({
  width = 20,
  height = 16,
  color = '#636567',
}: SvgProps) => (
  <Svg width={width} height={height} fill="none">
    <Path
      d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm0 4-8 5-8-5V2l8 5 8-5v2Z"
      fill={color}
    />
  </Svg>
);
