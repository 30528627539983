import React, { useState } from 'react';
import { Pressable } from 'react-native';
import { EyeOpenIcon, EyeClosedIcon } from '../../assets';

interface ShowHideProps {
  /**
   * Callback that will be executed when the icon is pressed
   */
  onPress?: (() => void) | (() => Promise<void>);
}
export const ShowHideIcon = ({ onPress }: ShowHideProps) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(true);
  const toggleShowHide = () => {
    setIsShowPassword(!isShowPassword);
    if (onPress) {
      onPress();
    }
  };
  return (
    <Pressable
      onPress={toggleShowHide}
      accessibilityRole="imagebutton"
      testID="show-hide-icon"
    >
      {isShowPassword ? (
        <EyeClosedIcon testID="eye-closed" />
      ) : (
        <EyeOpenIcon testID="eye-open" />
      )}
    </Pressable>
  );
};
