import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { ReactNode } from 'react';

export interface AuthProviderProps {
  children: ReactNode;
}

export type Session = CognitoUserSession | null;
export type User = CognitoUser | null;
export interface InputProps {
  email: string;
  password: string;
}
export interface ResetPasswordProps extends InputProps {
  code: string;
}
export interface CompleteNewPasswordProps {
  incompletePasswordUser: CognitoUser;
  password: string;
}

export interface ReducerAuthState {
  session: Session;
  user: User;
  userName: string;
  authMode?: AuthMode;
  email?: string;
  isLoading: boolean | null;
  isResendConfirmationLoading: boolean | null;
  isInitialLoad: boolean | null;
}

export interface AuthContextProps extends ReducerAuthState {
  signOut: () => Promise<void>;
  signIn: (values: { email: string; password: string }) => Promise<void>;
  completeNewPassword: (values: {
    incompletePasswordUser: CognitoUser;
    password: string;
  }) => Promise<void>;
  signUp: (values: { email: string; password: string }) => Promise<string>;
  confirmAccount: (values: { email: string; code: string }) => Promise<void>;
  updateSessionInfo: () => Promise<void>;
  sendResetPasswordCode: (values: Pick<InputProps, 'email'>) => Promise<void>;
  resendSignUp: (values: { email: string }) => Promise<void>;
  /**
   * @desc Called after calling sendResetPasswordCode
   */
  resetPassword(values: ResetPasswordProps): Promise<void>;
}

export enum AuthMode {
  SIGN_IN = 'SIGN_IN',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SIGN_OUT = 'SIGN_OUT',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  LOGGED_IN = 'LOGGED_IN',
}

export interface UpdateSession {
  type: string;
  payload: {
    session: Session;
  };
}

export interface UpdateUser {
  type: string;
  payload: {
    user: User;
  };
}

export interface UpdateLoadingState {
  type: string;
  payload: {
    isLoading: boolean;
  };
}

export interface UpdateResendConfirmationLoadingState {
  type: string;
  payload: {
    isResendConfirmationLoading: boolean;
  };
}

export interface SwitchMode {
  type: string;
  payload: {
    authMode: AuthMode;
    email?: string;
  };
}
