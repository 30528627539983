import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export function Power({
  height = 24,
  width = 24,
  color = '#636567',
  testID = 'powerIcon',
  ...props
}: SvgProps) {
  return (
    <Svg width={width} height={height} testID={testID} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2h-2v12h2V2Zm-1 20a9 9 0 0 0 5.62-16.03l-1.725 2.466a6 6 0 1 1-7.79 0L6.38 5.97A9 9 0 0 0 12 22Z"
        fill={color}
      />
    </Svg>
  );
}
