import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const HelpIcon = ({
  width = 24,
  height = 22,
  color = '#636567',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      d="M9 0a9 9 0 0 0-9 9v6c0 1.66 1.34 3 3 3h3v-7H2V9c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v7h4v2H9v2h6c1.66 0 3-1.34 3-3V9a9 9 0 0 0-9-9Z"
      fill={color}
    />
  </Svg>
);
