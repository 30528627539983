import { Modal, TouchableOpacity, View } from 'react-native';
import { createStyles, getPrimaryColorStyles } from './styles';
import React from 'react';
import { ModalProps } from './types';
import { useTheme } from '../../themes';
import { StyledText } from '../Typography/StyledText';

export default function CanopyModal({
  modalData,
  setModalData,
}: {
  modalData: ModalProps;
  setModalData: (modalData: ModalProps) => void;
}) {
  const dismissModal = () => {
    setModalData({
      ...modalData,
      isVisible: false,
    });
    modalData.onDismiss();
  };

  const retryCall = () => {
    setModalData({
      ...modalData,
      isVisible: false,
    });
    modalData.onAction();
  };

  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalData.isVisible}
      testID="sentinel-modal"
      style={{
        borderWidth: 0,
      }}
    >
      <View style={styles.modalContainer} testID="modalContainer">
        <View style={styles.modalTextContainer} testID="modalTextContainer">
          <StyledText
            style={styles.modalHeaderText}
            textStyle={theme.textStyles.header1Bold}
          >
            {modalData.headerText}
          </StyledText>
          <StyledText textStyle={theme.textStyles.bodyMedium}>
            {modalData.bodyTextOne}
          </StyledText>
          <StyledText textStyle={theme.textStyles.bodyMedium}>
            {modalData.bodyTextTwo}
          </StyledText>
        </View>
        <View style={styles.modalButtonWrapper} testID="modalButtonWrapper">
          <TouchableOpacity
            accessibilityRole="button"
            style={styles.modalButton}
            testID="modalButton"
            onPress={retryCall}
          >
            <StyledText
              testID="modalButtonText"
              style={[getPrimaryColorStyles(theme, true)]}
              textStyle={theme.textStyles.bodySemiBold}
            >
              {modalData.actionText}
            </StyledText>
          </TouchableOpacity>
          <View style={styles.modalButtonDivider} testID="buttonDivider" />
          <TouchableOpacity
            accessibilityRole="button"
            style={styles.modalButton}
            onPress={dismissModal}
          >
            <StyledText textStyle={theme.textStyles.bodySemiBold}>
              Cancel
            </StyledText>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}
