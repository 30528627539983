import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const TamperIcon = ({ color = '#303030', ...props }: SvgProps) => (
  <Svg width={26} height={20} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.96 10.183a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm-5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.962 3.514.536-.665.537-.666.002.002.005.003.014.01a3.658 3.658 0 0 1 .201.134c.13.091.31.223.524.396.426.345.99.854 1.555 1.524 1.127 1.338 2.272 3.338 2.272 5.931 0 2.594-1.145 4.594-2.273 5.932a11.522 11.522 0 0 1-1.554 1.524 9.536 9.536 0 0 1-.725.53l-.014.01-.005.003h-.002c0 .001-.001.002-.537-.664l-.536-.666h.002l.024-.017.11-.074c.1-.07.246-.177.424-.32a9.795 9.795 0 0 0 1.32-1.295c.962-1.142 1.908-2.81 1.908-4.963s-.946-3.82-1.909-4.962a9.8 9.8 0 0 0-1.319-1.294 7.761 7.761 0 0 0-.533-.396l-.025-.015-.002-.002ZM10.038 3.514l-.536-.665-.537-.666-.002.002-.005.003-.014.01a3.806 3.806 0 0 0-.201.134c-.13.091-.31.223-.524.396-.426.345-.99.854-1.555 1.524C5.537 5.59 4.392 7.59 4.392 10.183c0 2.594 1.145 4.594 2.272 5.932.564.67 1.129 1.18 1.555 1.524a9.558 9.558 0 0 0 .725.53l.014.01.005.003h.002c0 .001.001.002.537-.664l.536-.666h-.002l-.024-.017a7.78 7.78 0 0 1-.534-.395 9.797 9.797 0 0 1-1.32-1.294c-.962-1.142-1.908-2.81-1.908-4.963s.946-3.82 1.909-4.962a9.803 9.803 0 0 1 1.319-1.294 7.778 7.778 0 0 1 .534-.396l.024-.015.002-.002Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.962 1.817 19.54 1l.578-.817v.001l.002.001.006.004.015.011a4.352 4.352 0 0 1 .216.166c.14.112.334.274.564.486a13.352 13.352 0 0 1 1.673 1.87C23.808 4.363 25.04 6.816 25.04 10s-1.232 5.636-2.446 7.279a13.355 13.355 0 0 1-1.673 1.87 10.54 10.54 0 0 1-.78.65l-.015.012-.006.003-.002.002s-.001.001-.578-.816l-.577-.817.003-.001.026-.02a8.57 8.57 0 0 0 .574-.485c.385-.354.902-.887 1.42-1.587 1.036-1.402 2.054-3.448 2.054-6.09s-1.018-4.688-2.054-6.09a11.351 11.351 0 0 0-1.42-1.587 8.565 8.565 0 0 0-.575-.485l-.025-.02-.003-.001ZM7.038 1.817 6.46 1 5.883.183v.001L5.88.185 5.875.19 5.86.2a4.222 4.222 0 0 0-.216.166c-.14.112-.334.274-.564.486a13.35 13.35 0 0 0-1.673 1.87C2.192 4.363.96 6.816.96 10s1.231 5.636 2.446 7.279a13.352 13.352 0 0 0 1.673 1.87 10.56 10.56 0 0 0 .78.65l.015.012.006.003.002.002s.001.001.578-.816l.577-.817-.002-.001-.026-.02a8.58 8.58 0 0 1-.574-.485 11.356 11.356 0 0 1-1.421-1.587C3.979 14.688 2.96 12.642 2.96 10s1.018-4.688 2.054-6.09c.518-.7 1.035-1.233 1.42-1.587a8.575 8.575 0 0 1 .575-.485l.026-.02.002-.001Z"
      fill={color}
    />
  </Svg>
);
