import { StyleSheet, View } from 'react-native';
import { StyledText, StyledTextProps } from '../Typography/StyledText';
import React from 'react';
import pixelRatio from '../../utils/pixelRatio';
import { useTheme } from '../../themes';

export interface FormLabelProps
  extends Omit<StyledTextProps, 'children' | 'accessibilityRole'> {
  label?: string;
  renderTopRightButton?: () => JSX.Element;
}
const createStyles = () =>
  StyleSheet.create({
    labelContainer: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: pixelRatio.heightPercentageToDP('0.8'),
    },
  });
export function FormLabel({
  label,
  renderTopRightButton,
  ...rest
}: FormLabelProps) {
  const styles = createStyles();
  const theme = useTheme();
  return (
    <View style={styles.labelContainer}>
      {label && (
        <StyledText textStyle={theme.textStyles.formLabel} {...rest}>
          {label}
        </StyledText>
      )}

      {renderTopRightButton !== undefined && renderTopRightButton()}
    </View>
  );
}
