import React, { useEffect, useRef } from 'react';
import {
  ActivityIndicator,
  Animated,
  StyleSheet,
  View,
  ViewProps,
} from 'react-native';
import { ActivityIndicatorProps } from 'react-native';
import { StyledText, StyledTextProps } from '../Typography/StyledText';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';
export interface ErrorIndicatorProps extends ViewProps {
  error: string;
  loading: boolean;
  activityIndicatorProps?: ActivityIndicatorProps;
  errorTextProps?: StyledTextProps;
}

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    errorIndicatorContainer: {
      marginTop: 15,
    },
    errorTextContainer: {
      alignSelf: 'center',
    },
    errorText: {
      color: theme.alerts.error,
    },
  });

export function ErrorIndicator({
  error,
  loading,
  activityIndicatorProps,
  errorTextProps,
}: ErrorIndicatorProps) {
  const theme = useTheme();
  const styles = createStyles(theme);

  const animation = useRef(new Animated.Value(0));

  const shakeAnimation = () => {
    const deltaX = 20;
    const duration = 100;
    Animated.sequence([
      Animated.timing(animation.current, {
        toValue: -deltaX,
        duration: duration,
        useNativeDriver: true,
      }),
      Animated.timing(animation.current, {
        toValue: deltaX,
        duration: duration,
        useNativeDriver: true,
      }),
      Animated.timing(animation.current, {
        toValue: -deltaX,
        duration: duration,
        useNativeDriver: true,
      }),
      Animated.timing(animation.current, {
        toValue: 0,
        duration: duration,
        useNativeDriver: true,
      }),
    ]).start();
  };

  useEffect(() => {
    shakeAnimation();
  }, [error]);

  return (
    <View style={styles.errorIndicatorContainer}>
      {error !== '' && (
        <Animated.View
          style={[
            styles.errorTextContainer,
            { transform: [{ translateX: animation.current }] },
          ]}
        >
          <StyledText
            textStyle={theme.textStyles.header1Bold}
            style={styles.errorText}
            {...errorTextProps}
          >
            {error}
          </StyledText>
        </Animated.View>
      )}
      {loading && (
        <ActivityIndicator
          size="large"
          color={theme.colors.primaryOrange}
          testID="loading-indicator"
          {...activityIndicatorProps}
        />
      )}
    </View>
  );
}
