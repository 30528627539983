import { Colors, ColorsType } from './colors';
import CornerRadii, { CornerRadiiType } from './cornerRadii';
import ShadowValues, { ShadowValuesType } from './shadows';
import { TextStyles, TextStylesDark, TextStylesType } from './textStyles';

export interface ComponentTheme {
  primary: { main: string; lighter: string; contrastText: string };
  secondary: {
    main: string;
    darker: string;
    darkest: string;
    contrastText: string;
  };
  tertiary: { main: string; secondary: string };
  quaternary: { main: string; secondary: string };
  text: {
    main: string;
    secondary: string;
    accent: string;
    placeholder: string;
    inverse: string;
  };
  buttonText: {
    main: string;
    secondary: string;
    tertiary: string;
    quarternary: string;
  };
  disabled: {
    main: string;
    secondary: string;
  };
  background: { main: string; surface: string; surfaceSeparator: string };
  alerts: { error: string; success: string; warning: string };
  shadows: ShadowValuesType;
  radii: CornerRadiiType;
  colors: ColorsType;
  textStyles: TextStylesType;
}

const defaultTheme: ComponentTheme = {
  primary: {
    main: Colors.primaryOrange,
    lighter: Colors.primaryOrangeLighter,
    contrastText: Colors.white,
  },
  secondary: {
    main: Colors.white,
    darker: Colors.lightGreyRegularE,
    darkest: Colors.lightGreyRegularB,
    contrastText: Colors.primaryOrange,
  },
  tertiary: {
    main: Colors.lightGreyRegularE,
    secondary: Colors.darkGreyRegular6,
  },
  quaternary: {
    main: Colors.white,
    secondary: Colors.darkGreyRegular6,
  },
  text: {
    main: Colors.darkGreyRegular4,
    secondary: Colors.darkGreyRegular6,
    accent: Colors.primaryOrange,
    placeholder: Colors.darkGreyRegular6,
    inverse: Colors.white,
  },
  buttonText: {
    main: Colors.white,
    secondary: Colors.primaryOrange,
    tertiary: Colors.darkGreyRegular6,
    quarternary: Colors.darkGreyRegular6,
  },
  background: {
    main: Colors.lightGreyRegularF,
    surface: Colors.white,
    surfaceSeparator: Colors.lightGreyRegularE,
  },
  alerts: {
    error: Colors.trafficLightRed,
    success: Colors.trafficLightGreen,
    warning: Colors.trafficLightAmber,
  },
  disabled: {
    main: Colors.lightGreyRegularE,
    secondary: Colors.lightGreyRegularD,
  },
  shadows: ShadowValues,
  radii: CornerRadii,
  colors: Colors,
  textStyles: TextStyles,
};

export const darkTheme = {
  ...defaultTheme,
  text: {
    main: Colors.white,
    secondary: Colors.lightGreyRegularB,
    accent: Colors.primaryOrange,
    placeholder: Colors.lightGreyRegularB,
    inverse: Colors.black,
  },
  secondary: {
    main: Colors.darkGreyRegular2,
    darker: Colors.darkGreyRegular4,
    darkest: Colors.darkGreyRegular6,
    contrastText: Colors.primaryOrange,
  },
  buttonText: {
    main: Colors.darkGreyRegular2,
    secondary: Colors.primaryOrange,
    tertiary: Colors.lightGreyRegularB,
    quarternary: Colors.lightGreyRegularB,
  },
  background: {
    main: Colors.darkGreyRegular2,
    surface: Colors.darkGreyRegular3,
    surfaceSeparator: Colors.darkGreyRegular6,
  },
  alerts: {
    error: Colors.darkModeTrafficLightRed,
    success: Colors.darkModeTrafficLightGreen,
    warning: Colors.trafficLightAmber,
  },
  disabled: {
    main: Colors.darkGreyRegular4,
    secondary: Colors.lightGreyRegularD,
  },
  textStyles: TextStylesDark,
};

export default defaultTheme;
