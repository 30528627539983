import MarkdownToJSX from 'markdown-to-jsx';
import React from 'react';
import { View } from 'react-native';
import { useTheme } from '../../themes';
import { StyledText } from '../Typography/StyledText';

export interface MarkdownProps {
  children: string;
}

export function Markdown({ children }: MarkdownProps) {
  const theme = useTheme();

  const compilerOptions = {
    wrapper: View,
    forceWrapper: true,
    forceBlock: true,
    overrides: {
      h1: {
        component: StyledText,
        props: {
          style: theme.textStyles.header6Medium,
        },
      },
      h2: {
        component: StyledText,
        props: {
          style: theme.textStyles.header5Medium,
        },
      },
      h3: {
        component: StyledText,
        props: {
          style: theme.textStyles.header4Medium,
        },
      },
      h4: {
        component: StyledText,
        props: {
          style: theme.textStyles.header3Medium,
        },
      },
      h5: {
        component: StyledText,
        props: {
          style: theme.textStyles.header2Medium,
        },
      },
      h6: {
        component: StyledText,
        props: {
          style: theme.textStyles.header1Medium,
        },
      },
      br: {
        component: StyledText,
        props: {
          style: theme.textStyles.header6Medium,
        },
      },
      p: {
        component: StyledText,
        props: {
          style: {
            ...theme.textStyles.header1Medium,
            paddingTop: 8,
          },
        },
      },
      u: {
        component: StyledText,
        props: {
          style: {
            ...theme.textStyles.header1Medium,
            paddingTop: 8,
            textDecorationLine: 'underline',
          },
        },
      },
      strong: {
        component: StyledText,
        props: {
          style: {
            ...theme.textStyles.header1Bold,
            paddingTop: 8,
          },
        },
      },
      a: {
        component: StyledText,
        props: {
          style: theme.textStyles.header1Medium,
        },
      },
      span: {
        component: StyledText,
        props: {
          style: {
            ...theme.textStyles.header1Medium,
            textDecorationLine: 'underline',
          },
        },
      },
      li: {
        component: StyledText,
        props: {
          style: theme.textStyles.header1Medium,
        },
      },
      ol: {
        component: StyledText,
        props: {
          style: theme.textStyles.header1Medium,
        },
      },
      ul: {
        component: StyledText,
        props: {
          style: theme.textStyles.header1Medium,
        },
      },
      em: {
        component: StyledText,
        props: {
          style: {
            ...theme.textStyles.header1Italic,
            paddingTop: 8,
            fontStyle: 'italic',
          },
        },
      },
    },
  };

  // This will log what is rendered
  // console.log(compiler(children, compilerOptions));

  return <MarkdownToJSX options={compilerOptions}>{children}</MarkdownToJSX>;
}
