import React, { SetStateAction, useMemo } from 'react';
import {
  FlatList,
  FlatListProps,
  StyleProp,
  StyleSheet,
  ViewStyle,
  View,
} from 'react-native';
import { SvgProps } from 'react-native-svg';
import { InfoContainerItem } from '../InfoContainerItem';

export enum InfoContainerType {
  'display' = 'display',
  'pressable' = 'pressable',
  'input' = 'input',
}

export type InformationCardItem = {
  Icon?: (iconProps: SvgProps) => JSX.Element;
  content: string;
  secondaryText?: string;
  value?: () => string | React.ReactNode;
  infoContainerType?: InfoContainerType;
  onPress?: () => void;
  textPlaceHolder?: string;
  textDefaultValue?: string;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  RightDecorator?: (iconProps: SvgProps) => JSX.Element;
  updatedTextInput?: (value: SetStateAction<string>) => void | undefined;
  informationCardStyle?: StyleProp<ViewStyle> | undefined;
  disabled?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  decoratorContainerStyle?: StyleProp<ViewStyle>;
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingLeft: 16,
    },
  });

export type FlatListPickedProps = Omit<
  FlatListProps<InformationCardItem>,
  'data' | 'renderItem'
>;
export interface InfoContainerProps extends FlatListPickedProps {
  /**
   * Used to display a loading indicator.
   */
  isLoading?: boolean;
  /**
   * An information card item will take the following:
   * Icon: ({ width, height, testID, color, ...props }: SvgProps) => JSX.Element;
   * content: string;
   * value: () => string | React.ReactNode;
   */
  informationCardItems: InformationCardItem[];
  containerStyles?: StyleProp<ViewStyle> | undefined;
}

export function InfoContainer({
  isLoading = false,
  informationCardItems,
  containerStyles,
  testID = 'info-card-container',
  ...rest
}: InfoContainerProps) {
  const styles = createStyles();
  const itemsLength = useMemo(() => informationCardItems.length, []);
  return (
    <View style={[styles.container, containerStyles]} testID={testID}>
      <FlatList
        {...rest}
        data={informationCardItems}
        refreshing={isLoading}
        renderItem={({ item, index }) => (
          <InfoContainerItem
            item={item}
            index={index}
            itemsLength={itemsLength}
          />
        )}
      />
    </View>
  );
}
