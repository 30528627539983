import React from 'react';
import { View, StyleSheet, Pressable, ViewProps } from 'react-native';
import { InfoContainerType, InformationCardItem } from '../InfoContainer';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';
import { ItemDisplay } from '../ItemDisplay';

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    informationCardContainer: {
      backgroundColor: theme.background.surface,
      marginRight: 16,
    },
    informationCard: {
      flexDirection: 'row',
      height: 80,
      paddingHorizontal: 24,
    },
    cardBorder: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderColor: theme.background.surfaceSeparator,
    },
  });
export interface InfoContainerItemProps extends ViewProps {
  item: InformationCardItem;
  index?: number;
  itemsLength?: number;
}
export const InfoContainerItem = ({
  item,
  index = 0,
  itemsLength = 1,
  style,
}: InfoContainerItemProps) => {
  const theme = useTheme();
  const styles = createStyles(theme);
  const {
    onPress,
    accessibilityLabel,
    accessibilityHint,
    infoContainerType = InfoContainerType.display,
  } = item;
  const indexStyles = StyleSheet.create({
    borderStyles: {
      borderTopLeftRadius: index === 0 ? 8 : 0,
      borderTopRightRadius: index === 0 ? 8 : 0,
      borderBottomLeftRadius: index === itemsLength - 1 ? 8 : 0,
      borderBottomRightRadius: index === itemsLength - 1 ? 8 : 0,
    },
  });

  return (
    <View
      style={[
        styles.informationCardContainer,
        indexStyles.borderStyles,
        style ? style : null,
      ]}
    >
      {infoContainerType === InfoContainerType.pressable ? (
        <Pressable
          style={[
            styles.informationCard,
            index !== itemsLength - 1 && styles.cardBorder,
          ]}
          onPress={onPress}
          accessibilityLabel={accessibilityLabel}
          accessibilityHint={accessibilityHint}
          disabled={item.disabled}
          testID={item.content}
        >
          <ItemDisplay item={item} index={index} />
        </Pressable>
      ) : (
        <View
          style={[
            styles.informationCard,
            index !== itemsLength - 1 && styles.cardBorder,
          ]}
        >
          <ItemDisplay item={item} index={index} />
        </View>
      )}
    </View>
  );
};
