import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ChatBubble = ({ testID, color = '#636567' }: SvgProps) => (
  <Svg width={20} height={20} fill="none" testID={testID}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M16 11a.97.97 0 0 1-1 1H5l-5 5V1c0-.5.5-1 1-1h14c.5 0 1 .5 1 1v10Zm1-7h2c.5 0 1 .5 1 1v15l-4-4H6c-.5 0-1-.5-1-1v-2h12V4Z"
      clipRule="evenodd"
    />
  </Svg>
);
