import React, { ForwardedRef, forwardRef } from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { StyledText, StyledTextProps } from '../Typography/StyledText';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';
export interface InputBoxProps extends ViewProps {
  label: string;
  textInputProps?: TextInputProps;
  inputValue: string;
  inputBoxContainerStyle?: StyleProp<ViewStyle>;
  rightDecorator: string;
  rightDecoratorProps?: StyledTextProps;
}

const createStyles = (theme: ComponentTheme) =>
  StyleSheet.create({
    textInputContainer: {
      alignItems: 'flex-end',
      paddingRight: 10,
      justifyContent: 'center',
    },
    inputText: {
      textAlign: 'right',
      width: 140,
      lineHeight: Platform.OS == 'ios' ? 0 : undefined,
      color: theme.text.secondary,
    },
    labelContainer: {
      flex: 5,
      flexGrow: 10,
    },
    inputBoxContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    rightDecoratorContainer: {
      justifyContent: 'center',
      paddingRight: '3%',
    },
  });

export const InputBox = forwardRef(function InputBox(
  {
    label,
    inputValue,
    textInputProps,
    inputBoxContainerStyle,
    rightDecorator,
    rightDecoratorProps,
    ...rest
  }: InputBoxProps,
  ref: ForwardedRef<TextInput>,
) {
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <View style={[styles.inputBoxContainer, inputBoxContainerStyle]} {...rest}>
      <View style={[styles.labelContainer]}>
        <StyledText textStyle={theme.textStyles.header1Bold}>
          {label}
        </StyledText>
      </View>

      <View style={[styles.textInputContainer]}>
        <TextInput
          ref={ref}
          style={[theme.textStyles.bodyMedium, styles.inputText]}
          placeholder={'0'}
          placeholderTextColor={theme.text.placeholder}
          defaultValue={inputValue === '0' ? undefined : inputValue}
          maxLength={4}
          inputMode="numeric"
          {...textInputProps}
        />
      </View>
      <View style={styles.rightDecoratorContainer}>
        <StyledText
          testID={`right-decorator-id`}
          textStyle={theme.textStyles.bodyMedium}
          {...rightDecoratorProps}
        >
          {rightDecorator}
        </StyledText>
      </View>
    </View>
  );
});
