import React, { useEffect, useState, MutableRefObject } from 'react';
import { FlatList, Platform, Pressable, View, ViewStyle } from 'react-native';
import { DropDownArrow } from '../../assets';
import { useTheme } from '../../themes';
import { StyledButton } from '../Button';
import { StyledText } from '../Typography/StyledText';
import {
  createStyles,
  getBorderRadiusStyles,
  getPressedColorStyles,
  getElementPadding,
} from './styles';

type Item = string;

export interface DropDownProps {
  /**
   * The items (values) you would like to have displayed and selectable in the dropdown menu.
   */
  items: Item[];
  testID?: string;
  /**
   * The text displayed initially on the dropdown button before a user selects a value.
   */
  placeholderText: string;
  /**
   *  Triggered whenever a user selects a value in the dropdown. This callback gives you access to the selected value for your function.
   */
  onValueChange?: (item: Item) => void;
  /**
   * Allows you to bypass selecting a value from the dropdown and displays your "selectedValue".
   * Useful for cases where another button interacts with the dropdown (such as a clear all selections button).
   */
  selectedValue?: Item;
  /**
   * Allows customization of the dropdown button.
   */
  dropdownButtonStyle?: ViewStyle;
  /**
   * Allows customization of the dropdown menu.
   */
  dropdownListContainerStyle?: ViewStyle;
}

function closeDropdownListener(
  listRef: MutableRefObject<any>,
  dropdownRef: MutableRefObject<any>,
  hideList: () => void,
) {
  useEffect(() => {
    const listener = (event: any) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        hideList();
      }
    };

    if (Platform.OS === 'web') {
      document.addEventListener('mousedown', listener);
    }

    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('mousedown', listener);
      }
    };
  }, [listRef, hideList]);
}

export function DropDown({
  items,
  testID = 'dropdown',
  placeholderText,
  onValueChange = () => {},
  selectedValue,
  dropdownButtonStyle,
  dropdownListContainerStyle,
}: DropDownProps) {
  const [buttonText, setButtonText] = useState(placeholderText);
  const [isListVisible, setIsListVisible] = useState(false);

  useEffect(() => {
    setButtonText(placeholderText);
  }, []);

  useEffect(() => {
    setButtonText(selectedValue ?? placeholderText);
  }, [selectedValue]);

  const theme = useTheme();
  const styles = createStyles(theme);
  const listRef = React.useRef<any>(null);
  const dropdownRef = React.useRef<any>(null);

  closeDropdownListener(listRef, dropdownRef, () => setIsListVisible(false));

  return (
    <View style={styles.dropdownContainer} ref={dropdownRef} testID={testID}>
      <StyledButton
        onPress={() => setIsListVisible(!isListVisible)}
        style={[styles.dropdownButton, dropdownButtonStyle]}
      >
        <View style={styles.menuContainer}>
          <View style={styles.textContainer}>
            {buttonText === placeholderText ? (
              <StyledText style={styles.placeholderText}>
                {buttonText}
              </StyledText>
            ) : (
              <StyledText style={styles.listItemText}>{buttonText}</StyledText>
            )}
          </View>
          <View style={styles.iconContainer}>
            <DropDownArrow
              testID="drop-down-arrow-icon"
              color={theme.text.placeholder}
            />
          </View>
        </View>
      </StyledButton>
      {isListVisible && (
        <View
          ref={listRef}
          style={[styles.flatListContainer, dropdownListContainerStyle]}
        >
          <FlatList
            testID="menu-item-list"
            data={items}
            keyExtractor={(listItem) => listItem}
            renderItem={({ item, index }) => (
              <Pressable
                accessibilityRole="button"
                onPress={() => {
                  onValueChange(item);
                  setButtonText(item);
                  setIsListVisible(!isListVisible);
                }}
              >
                {({ pressed }) => (
                  <View
                    style={[
                      styles.pressableItem,
                      getBorderRadiusStyles(index, items),
                      getPressedColorStyles(theme, pressed),
                    ]}
                  >
                    <StyledText
                      style={[
                        styles.listItemText,
                        getElementPadding(index, items),
                      ]}
                    >
                      {item}
                    </StyledText>
                  </View>
                )}
              </Pressable>
            )}
          />
        </View>
      )}
    </View>
  );
}
