import { ComponentTheme } from '../../themes/defaultTheme';
import { DimensionValue, StyleSheet } from 'react-native';
import { useResponsiveWidth } from 'react-native-responsive-dimensions';

const createStyles = (theme: ComponentTheme) => {
  return StyleSheet.create({
    placeholderText: {
      color: theme.text.placeholder,
    },
    flatListContainer: {
      borderColor: theme.secondary.darkest,
      borderRadius: 10,
      borderWidth: 1,
      width: '100%',
      flexDirection: 'row',
      position: 'absolute',
      backgroundColor: theme.background.surface,
      top: '100%',
    },
    textContainer: {
      flex: 0.8,
      alignItems: 'flex-start',
    },
    iconContainer: {
      flex: 0.2,
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    menuContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    listItemText: {
      color: theme.text.main,
      paddingLeft: '2%',
      alignSelf: 'flex-start',
    },
    pressableItem: {
      borderWidth: 0.5,
    },
    dropdownContainer: {
      zIndex: 99,
    },
    dropdownButton: {
      borderColor: theme.background.surface,
      minHeight: 56,
      paddingLeft: useResponsiveWidth(1),
      backgroundColor: theme.background.surface,
    },
  });
};

const getElementPadding = (
  index: number,
  items: string[],
): Record<'paddingBottom', DimensionValue | undefined> => {
  const isLastElement = index === items.length - 1;
  return {
    paddingBottom: isLastElement ? 0 : '1%',
  };
};

const getBorderRadiusStyles = (index: number, items: string[]) => {
  const firstElementBorderRadius = {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  };
  const lastElementBorderRadius = {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  };
  const singleElementBorderRadius = { borderRadius: 10 };
  const defaultRadius = { borderRadius: 0 };

  const result =
    items.length === 1
      ? singleElementBorderRadius
      : index === 0
      ? firstElementBorderRadius
      : index === items.length - 1
      ? lastElementBorderRadius
      : defaultRadius;

  return result;
};

const getPressedColorStyles = (theme: ComponentTheme, pressed: boolean) => {
  return {
    borderColor: pressed ? theme.secondary.darker : theme.background.surface,
    backgroundColor: pressed
      ? theme.secondary.darker
      : theme.background.surface,
  };
};

export {
  createStyles,
  getElementPadding,
  getBorderRadiusStyles,
  getPressedColorStyles,
};
