import React, { useState } from 'react';
import {
  Pressable,
  StyleSheet,
  PressableProps,
  View,
  Text,
} from 'react-native';
import { useTheme } from '../../themes';
import { ComponentTheme } from '../../themes/defaultTheme';

export interface IconButtonProps extends PressableProps {
  icon: React.ReactNode;
  label?: string;
}

function getBackgroundColor(isPressed: boolean, theme: ComponentTheme): string {
  return isPressed
    ? theme.primary.main
    : theme.secondary.darkest;
}

const createStyles = (theme: ComponentTheme, isPressed: boolean) =>
  StyleSheet.create({
    pressableContainer: {
      flex: 1,
      alignItems: 'center',
    },
    iconContainer: {
      flex: 0.6,
      width: '60%',
      justifyContent: 'center',
      borderRadius: 50,
      ...theme.shadows.small,
      color: theme.text.secondary,
      backgroundColor: getBackgroundColor(isPressed, theme),
      alignItems: 'center',
    },
    label: {
      flex: 0.4,
      fontSize: 16,
      flexDirection: 'column-reverse',
      display: 'flex',
    },
    labelText: {
      color: theme.text.main
    },
    iconContainerNoLabel: {
      flex: 1,
      width: '100%',
    },
  });

export function IconButton({
  disabled = false,
  onPress,
  testID = 'icon-button',
  accessibilityLabel,
  accessibilityHint,
  icon,
  label,
}: IconButtonProps) {
  const [isPressed, setIsPressed] = useState(false);

  const theme = useTheme();

  const styles = createStyles(theme, isPressed);
  return (
    <>
      <Pressable
        style={[styles.pressableContainer]}
        disabled={disabled}
        onPress={onPress}
        onPressIn={(): void => setIsPressed(true)}
        onPressOut={(): void => setIsPressed(false)}
        testID={testID}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
      >
        <View
          style={[styles.iconContainer, !label && styles.iconContainerNoLabel]}
        >
          {icon}
        </View>

        {label && (
          <View style={styles.label}>
            <Text style={styles.labelText}>{label}</Text>
          </View>
        )}
      </Pressable>
    </>
  );
}
