import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const CollapseIcon = ({
  width = 24,
  height = 24,
  fill = '#fff',
  ...rest
}: SvgProps) => (
  <Svg width={width} height={height} fill="none" {...rest}>
    <Path
      fill={fill}
      fillRule="evenodd"
      d="M15 7.586 19.586 3 21 4.414 16.414 9H21v2h-8V3h2v4.586Zm-6 8.828L4.414 21 3 19.586 7.586 15H3v-2h8v8H9v-4.586Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default CollapseIcon;
