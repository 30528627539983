import React from 'react';
import { ActivityIndicator, Switch, SwitchProps } from 'react-native';
import { useTheme } from '../../themes';

interface CanopySwitchProps extends SwitchProps {
  value: boolean;
  /**
   *  Triggered whenever a user toggles the switch. Allows you to pass in a function (such as
   * changing the state of another relevant component).
   */
  toggle: () => void;
  /**
   * Used to display a loading indicator.
   */
  isLoading?: boolean;
  testID?: string;
}

export function CanopySwitch({
  value,
  toggle,
  isLoading = false,
  testID = 'sentinel-switch',
}: CanopySwitchProps): JSX.Element {
  const theme = useTheme();
  return (
    <>
      {isLoading ? (
        <ActivityIndicator
          size="large"
          color={theme.primary.main}
          testID="switch-loading-indicator"
        />
      ) : (
        <Switch
          trackColor={{
            false: theme.secondary.darkest,
            true: theme.primary.main,
          }}
          //@ts-expect-error type
          activeThumbColor={theme.colors.white}
          thumbColor={theme.colors.white}
          ios_backgroundColor={theme.secondary.darkest}
          value={value}
          onValueChange={toggle}
          testID={testID}
        />
      )}
    </>
  );
}
