import {
  UpdateSession,
  ReducerAuthState,
  Session,
  AuthMode,
  SwitchMode,
  UpdateUser,
  UpdateLoadingState,
  User,
  UpdateResendConfirmationLoadingState,
} from './types';

export const updateSession = (session: Session): UpdateSession => ({
  type: 'UPDATE_SESSION',
  payload: {
    session,
  },
});

export const updateUser = (user: User): UpdateUser => ({
  type: 'UPDATE_USER',
  payload: {
    user,
  },
});

export const updateLoadingState = (isLoading: boolean) => ({
  type: 'UPDATE_LOADING',
  payload: {
    isLoading,
  },
});

export const updateResendConfirmationLoadingState = (
  isResendConfirmationLoading: boolean,
) => ({
  type: 'UPDATE_ACCOUNT_CONFIRM_LOADING',
  payload: {
    isResendConfirmationLoading,
  },
});

export const updateInitialLoadingState = (isLoading: boolean) => ({
  type: 'UPDATE_INITIAL_LOADING',
  payload: {
    isLoading,
  },
});

export const switchMode = (authMode: AuthMode, email?: string) => ({
  type: 'SWITCH_MODE',
  payload: {
    authMode,
    email,
  },
});

export function authReducer(
  state: ReducerAuthState,
  action:
    | UpdateSession
    | UpdateUser
    | UpdateLoadingState
    | UpdateResendConfirmationLoadingState
    | SwitchMode,
): ReducerAuthState {
  switch (action.type) {
    case 'SWITCH_MODE':
      return {
        ...state,
        authMode: (action as unknown as SwitchMode).payload.authMode,
        email: (action as unknown as SwitchMode).payload.email ?? state.email,
      };
    case 'UPDATE_INITIAL_LOADING':
      return {
        ...state,
        isInitialLoad: (action as unknown as UpdateLoadingState).payload
          .isLoading,
      };
    case 'UPDATE_USER':
      const user = (action as unknown as UpdateUser).payload.user;
      if (user === null) {
        return {
          ...state,
          session: null,
          user: null,
        };
      } else {
        return {
          ...state,
          userName: user?.getUsername(),
          user,
        };
      }
    case 'UPDATE_SESSION':
      const session = (action as unknown as UpdateSession).payload.session;
      if (session === null) {
        return {
          ...state,
          session: null,
        };
      } else {
        return {
          ...state,
          email: session?.getIdToken()?.payload?.email,
          session,
        };
      }
    case 'UPDATE_LOADING':
      const { payload } = action as unknown as UpdateLoadingState;
      const { isLoading } = payload;
      return {
        ...state,
        isLoading,
      };
    case 'UPDATE_ACCOUNT_CONFIRM_LOADING':
      return {
        ...state,
        isResendConfirmationLoading: (
          action as unknown as UpdateResendConfirmationLoadingState
        ).payload.isResendConfirmationLoading,
      };
    default:
      return state;
  }
}
